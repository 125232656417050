import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./hero.scss";

class Hero extends Component {
  state = {
    info: [],
  };

  render() {
    return (
      <Container className="hero px-0">
        <div>
          <Col>
            <div className="subText">Available Now</div>
            <h1 className="headText">
              Get Your 2023
              <br />
              Custom Jobs Report
            </h1>
            <div className="subText">At No Cost</div>
          </Col>
        </div>
      </Container>
    );
  }
}

export default Hero;
