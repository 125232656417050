module.exports = {
  endpoint() {
    let endpoint = "";
    if (process.env.NODE_ENV === "development") {
      endpoint = "https://utiedu-apihub-dev.azurewebsites.net/jobs";
    } else {
      endpoint = "https://utiedu-apihub-dev.azurewebsites.net/jobs";
    }
    // console.log(endpoint);
    return endpoint;
  },
  lgEndpoint() {
    let ep = "";
    if (process.env.NODE_ENV === "development") {
      ep = "https://leadgatewayqa.uti.edu/api/leads";
    } else {
      ep = "https://leadgateway.uti.edu/api/leads";
    }
    // console.log(ep);
    return ep;
  },
  lg_QAEndpoint() {
    let ep = "";
    ep = "https://leadgatewayqa.uti.edu/api/leads";
    // console.log(ep);
    return ep;
  },
  apiHub() {
    let ep = "";
    ep = "https://rev.uti.edu/api/interested";
    // console.log(ep);
    return ep;
  },
};
