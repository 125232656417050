module.exports.dlEventCheck = (eventname, acquisitionpoint) => {
  try {
    let result = window.dataLayer.find(function (obj) {
      return (
        obj["event"] === eventname &&
        obj["formId"].indexOf(acquisitionpoint) > -1
      );
    });
    return result !== undefined;
  } catch (err) {
    return true;
  }
};
