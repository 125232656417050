import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./topNav.scss";
import { Row, Col, Container } from "react-bootstrap";
import logo from "../../media/svg/logo.svg";

class TopNav extends Component {
  state = {
    info: [],
  };

  render() {
    return (
      <Container fluid>
        <Container className="topnav px-0">
          <Row>
            <Col md={6} sm={6} xs={6}>
              <a href="/">
                {" "}
                <img src={logo} alt="UTI Logo" width="110" height="50" />
              </a>
            </Col>
            <Col md={6} sm={6} xs={6} className="d-none d-lg-block d-md-block">
              <span className="call-now-to-learn-more">
                Call now to learn more
              </span>
              <a className="cta-number-dt" href="tel:8009137524">
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="#d50019"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                    ></path>
                  </g>
                </svg>
                800-913-7524
              </a>
            </Col>
            <Col md={6} sm={6} xs={6} className="d-lg-none d-md-none">
              <a className="cta-number" href="tel:8009137524">
                <svg
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="#d50019"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                    ></path>
                  </g>
                </svg>
                800-913-7524
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default TopNav;
