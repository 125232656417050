import React, { useContext, useState } from "react";
// import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form } from "react-bootstrap";
import Input from "../../minor/formParts/input";
import DropDown from "../../minor/formParts/dropDown";
import FORM_INPUTS from "../../data/formInputs";
import { FormContext } from "../../context/formContext";
import "./extraInfo.scss";
//import { countReset } from "console";

const ExtraInfo = (prop) => {
  const payload = useContext(FormContext);
  const [dobTouched, setDobTouched] = useState(false);
  const [hsGradTouched, setHSGradTouched] = useState(false);
  const [eduTypeTouched, setEduTypeTouched] = useState(false);

  const enteredDOBIsValid = payload.birthday !== "";
  const enteredEduTypeIsValid =
    payload.age >= 20 ? true : payload.age < 20 && payload.educationType !== "";
  const enteredHSGradIsValid =
    payload.age < 20 ? true : payload.age >= 20 && payload.hsGrad !== "";

  const dobInputIsInvalid = !enteredDOBIsValid && dobTouched;
  const eduTypeIsInvalid = !enteredEduTypeIsValid && eduTypeTouched;
  const hsGradInputIsInvalid = !enteredHSGradIsValid && hsGradTouched;

  let stepIsValid = false;

  const formSubmissionHandler = (event) => {
    // console.log("formSubmissionHandler");
    event.preventDefault();
    if (stepIsValid) {
      prop.submit();
      window.scrollTo(0, 0);
      return;
    }
  };

  const dobChangeHandler = (event) => {
    if (payload.birthday !== "") {
      setDobTouched(true);
    }
  };

  const eduTypeChangeHandler = (event) => {
    if (event.target.value !== "") {
      setEduTypeTouched(true);
    }
  };

  const hsGradChangeHandler = (event) => {
    if (event.target.value !== "") {
      setHSGradTouched(true);
    }
  };

  const dobBlurHandler = (event) => {
    setDobTouched(true);
  };

  const eduTypeBlurHandler = (event) => {
    setEduTypeTouched(true);
  };

  const hsGradBlurHandler = (event) => {
    //console.log("blur");
    setHSGradTouched(true);
  };

  // console.log(`enteredDOBIsValid: ${enteredDOBIsValid}`);
  // console.log(`enteredHSGradIsValid: ${enteredHSGradIsValid}`);
  // console.log(`enteredEduTypeIsValid: ${enteredEduTypeIsValid}`);
  if (enteredDOBIsValid && enteredHSGradIsValid && enteredEduTypeIsValid) {
    stepIsValid = true;
  }
  // console.log(`Extra Info stepIsValid: ${stepIsValid}`)

  const dobInputClasses = dobInputIsInvalid ? "invalid" : "";
  const eduTypeInputClasses = eduTypeIsInvalid ? "invalid" : "";
  const hsGradInputClasses = hsGradInputIsInvalid ? "invalid" : "";

  let {
    fieldType = "text",
    placeholder,
    label,
    className,
    controlId,
  } = FORM_INPUTS.hsGrad;

  return (
    <Container className="inputContainer">
      <Form className="">
        <Row>
          <Col md={{span: 12}}>
            <Input
              settings={FORM_INPUTS.birthday}
              isError={dobInputClasses}
              onBlur={dobBlurHandler}
              isChanged={dobChangeHandler}
              max={8}
            />
            {dobInputClasses && (
              <p className="error-text">Please enter valid birthday</p>
            )}
          </Col>
        </Row>
        {payload.age < 20 && (
          <Row>
            <Col md={{span:12}}>
              <DropDown
                settings={FORM_INPUTS.educationType}
                isError={eduTypeInputClasses}
                onBlur={eduTypeBlurHandler}
                isChanged={eduTypeChangeHandler}
              />
              {eduTypeInputClasses && (
                <p className="error-text">
                  Please select your current level of education
                </p>
              )}
            </Col>
          </Row>
        )}

        {payload.age > 19 && (
          <Row>
            <Col md={{span:12}}>
              <p className="hs-check">
                Do you have (or are you getting) a high school diploma?
              </p>
              <Form.Group controlId={`form.${controlId}`}>
                {["radio"].map((type) => (
                  <div key={`default-${type}`} className="mb-3 hs-check">
                    <Form.Check
                      type={type}
                      name="hsCheck"
                      id={`${controlId}-yes`}
                      label={`Yes`}
                      onChange={(e) => {
                        payload.updateState(controlId, true);
                      }}
                    />
                    <Form.Check
                      type={type}
                      name="hsCheck"
                      id={`${controlId}-no`}
                      label={`No`}
                      onChange={(e) => {
                        payload.updateState(controlId, false);
                      }}
                    />
                  </div>
                ))}
              </Form.Group>
              {hsGradInputClasses && (
                <p className="error-text">
                  Please let us know if you have a high school diploma or
                  equivalent
                </p>
              )}
            </Col>
          </Row>
        )}
      </Form>
      <div className="btn-divv">
        <div className="previousStep-btn" onClick={prop.previousStep}>
          Previous Step
        </div>

        <div className="form-actions ">
          <button
            className="cta-btn"
            disabled={!stepIsValid}
            onClick={formSubmissionHandler}
          >
            Access Report &gt;
          </button>
        </div>

        {/* <div
          
          className={"cta-btn "}
          onClick={prop.submit} disabled={!stepIsValid}
        >
          <p className="btn-text" >
            ACCESS REPORT &gt;{" "}
          </p>
        </div> */}
      </div>
    </Container>
  );
};

export default ExtraInfo;
