import React, { Component } from "react";
//import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "./subFooter.scss";

class SubFooter extends Component {
    state = {
        info: [],
    };


render() {
    return (
        <Container fluid className="sub-footer mx-0 px-0">
            <div className="sub-text">
            © 2023 Universal Technical Institute, Inc. 
            </div>

        </Container>
    );
}
}

export default SubFooter;
