import React, { useContext, useState, useRef } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import { FormContext } from "../../context/formContext";
import { Container, Row, Col, Form } from "react-bootstrap";
import Input from "../../minor/formParts/input";
import DropDown from "../../minor/formParts/dropDown";
import Checkbox from "../../minor/formParts/checkbox";
import FORM_INPUTS from "../../data/formInputs";
import "./contactForm.scss";
// import { render } from "@testing-library/react";

const ContactForm = (props) => {
  //console.log("ContactForm()");
  const payload = useContext(FormContext);

  // const previousStep = () => {
  //   javascript: window.scrollTo(0, 0);
  // };

  // const proceed = true;

  /// Touched
  // const [fNameValid, setFnameValid] = useState(false);
  const [fNameTouched, setFnameTouched] = useState(false);
  const [lNameTouched, setLnameTouched] = useState(false);
  const [ageTouched, setAgeTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [hsGradYearTouched, setHSGradYearTouched] = useState(false);
  const [milReleaseDateTouched, setMilReleaseDateTouched] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [stepIsValid, setStepIsValid] = useState(false);
  ////

  useRef(() => {
    // console.log("() for contactForm");
    setStepIsValid(false);
    setStepIsValid(
      payload.firstName !== "" &&
        payload.lastName !== "" &&
        payload.age !== "" &&
        payload.zipCode !== "" &&
        payload.zipCode.length == 5 &&
        isValidPhoneNumber(payload.phone, "US") &&
        FORM_INPUTS.email.options.regx.test(payload.email) &&
        (payload.age !== "" && payload.age < 20
          ? /\d{4}/.test(payload.hsGradYear)
          : true) &&
        (payload.age !== "" && payload.age >= 18 && payload.militaryStatus
          ? FORM_INPUTS.militaryReleaseDate.options.regx.test(
              payload.militaryReleaseDate
            )
          : true)
    );
  });

  const fNameComponentRef = useRef();
  const lNameComponentRef = useRef();
  const ageComponentRef = useRef();
  const zipComponentRef = useRef();
  const phoneComponentRef = useRef();
  const emailComponentRef = useRef();
  const hsGradYearComponentRef = useRef();
  const milReleaseDateComponentRef = useRef();

  const fNameChangeHandler = (event) => {
    if (payload.firstName !== "") {
      setFnameTouched(true);
    }
  };

  const lNameChangeHandler = (event) => {
    if (payload.lastName !== "") {
      setLnameTouched(true);
    }
  };

  const ageChangeHandler = (event) => {
    if (payload.age !== "") {
      setAgeTouched(true);
    }
  };

  const zipChangeHandler = (event) => {
    if (payload.zipCode !== "") {
      setZipTouched(true);
    }
  };

  const phoneChangeHandler = (event) => {
    //const isValidPhone = isValidPhoneNumber(event.target.value, "US");
    setPhoneTouched(true);
  };

  const fNameBlurHandler = (event) => {
    setFnameTouched(true);
  };

  const lNameBlurHandler = (event) => {
    setLnameTouched(true);
  };

  const ageBlurHandler = (event) => {
    setAgeTouched(true);
  };

  const zipBlurHandler = (event) => {
    setZipTouched(true);
  };

  const phoneBlurHandler = (event) => {
    setPhoneTouched(true);
  };

  const emailInputBlurHandler = (event) => {
    setEmailTouched(true);
  };

  const hsGradYearBlurHandler = (event) => {
    setHSGradYearTouched(true);
  };

  const milReleaseDateBlurHandler = (event) => {
    setMilReleaseDateTouched(true);
  };

  // Submission Handler //
  function formSubmissionHandler(event) {
    //console.log("formSubmissionHandler()");
    event.preventDefault();
    window.scrollTo(0, 0);
    setSubmitClicked(true);

    if (!fNameTouched) {
      fNameComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.firstName.controlId
      );
    }
    if (!lNameTouched) {
      lNameComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.lastName.controlId
      );
    }
    if (!ageTouched) {
      ageComponentRef.current.handleOnBlur(null, FORM_INPUTS.age.controlId);
    }
    if (!zipTouched) {
      zipComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.zipCode.controlId
      );
    }
    if (!phoneTouched) {
      phoneComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.phone.controlId
      );
    }
    if (!emailTouched) {
      emailComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.email.controlId
      );
    }
    //console.log(`payload age: ${payload.age}`);
    if (payload.age !== "" && payload.age < 20 && !hsGradYearTouched) {
      hsGradYearComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.hsGradYear.controlId
      );
    }
    if (
      payload.age !== "" &&
      payload.age >= 18 &&
      payload.militaryStatus === true &&
      !milReleaseDateTouched
    ) {
      milReleaseDateComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.militaryReleaseDate.controlId
      );
    }

    let fnameisvalid = payload.firstName !== "";
    let lnameisvalid = payload.lastName !== "";
    let ageisvalid = payload.age !== "";
    document.getElementById(`select-${FORM_INPUTS.age.controlId}`).blur();
    let zipisvalid = payload.zipCode !== "" && payload.zipCode.length == 5;
    let phoneisvalid = /555-?555-?5555/.test(payload.phone)
      ? true
      : isValidPhoneNumber(payload.phone, "US")
      ? true
      : false;
    let emailisvalid = FORM_INPUTS.email.options.regx.test(payload.email);
    let hsgradisvalid =
      payload.age < 20 ? /\d{4}/.test(payload.hsGradYear) : true;

    let milreleasedateisvalid =
      payload.age >= 18 && payload.militaryStatus === true
        ? FORM_INPUTS.militaryReleaseDate.options.regx.test(
            payload.militaryReleaseDate
          )
        : true;

    /// GTM Form-Submit DL Event
    let ap = payload.payload.AdditionalInfo.filter((el) => {
      return el["Key"] === "AcquisitionPoint";
    })[0]["Value"];

    window.dataLayer.push({
      event: "form-submit",
      formId: ap,
      age: payload.age,
      areaOfInterest: payload.AOI,
      //campus: this.form.Campus,
      //'educationLevel': dataPayload.Education != undefined ? dataPayload.Education : "",
      //'email': dataPayload.EmailAddresses[0].Addresses,
      //'errorMessage': errMsg,
      //'formName': 'Paid Media Landing Page' + formName,
      formName: "Custom Jobs Report",
      formType: "inquiry",
      inquiryDate: new Date(new Date().toString().split("GMT")[0] + " UTC")
        .toISOString()
        .split(".")[0],
      isMilitary: payload.militaryStatus,
      leadId: payload.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "InquiryId";
      })[0]["Value"],
      referralPage: document.referrer,
      stepNumber: payload.step,
      //'userAgent': dataManager.userAgent().UserAgent === "" ? undefined : dataManager.userAgent().UserAgent
    });
    /// END GTM

    if (
      fnameisvalid &&
      lnameisvalid &&
      ageisvalid &&
      zipisvalid &&
      phoneisvalid &&
      emailisvalid &&
      hsgradisvalid &&
      milreleasedateisvalid
    ) {
      //console.log("payload all valid");

      setStepIsValid(true);
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: "form-success",
      // });
      props.progressStep();
      window.scrollTo(0, 0);

      // window.dataLayer.push({
      //   event: "form-success",
      //   formId: "UTIForm042"
      // });

      return;
    } else {
      //console.log("payload NOT all valid");
      setStepIsValid(false);
    }
  }

  function formatMilitaryRelease() {
    // console.log("formatMilitaryRelease()");
    let length = null;
    let mask = this.form.MilitarySeparation;

    // 1. Remove any unnecessary characters such as spaces, underscores, dashes, and letters.
    let formattedNumber = mask.replace(/[\D._\-()]+/g, "");

    // 2. Get length of all digits without numeric characters
    length = mask.replace(/\D/g, "").toString().length;

    // 3. Format
    if (length > 2) {
      mask =
        formattedNumber.substring(0, 2) + "/" + formattedNumber.substring(2, 6);
      this.form.MilitarySeparation = mask;
    }

    // 4. return the phone number
    this.form.MilitarySeparation = mask;
  }

  return (
    <Container className="form-container p-0">
      <div>
        <Col>
          <Form>
            <Row>
              <Col md={12}>
                <Input
                  settings={FORM_INPUTS.firstName}
                  defaultValue={props.payload.firstName}
                  //isError={firstNameInputClasses}
                  onBlur={fNameBlurHandler}
                  ref={fNameComponentRef}
                  //isChanged={fNameChangeHandler}
                />
              </Col>
              <Col md={12}>
                <Input
                  settings={FORM_INPUTS.lastName}
                  defaultValue={props.payload.lastName}
                  //isError={lastNameInputClasses}
                  onBlur={lNameBlurHandler}
                  ref={lNameComponentRef}
                  //isChanged={lNameChangeHandler}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={{ span: 4 }}
                sm={{ span: 4 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <DropDown
                  settings={FORM_INPUTS.age}
                  defaultValue={props.payload.age}
                  //isError={ageInputClasses}
                  onBlur={ageBlurHandler}
                  //isChanged={ageChangeHandler}
                  ref={ageComponentRef}
                  //cols="col-4"
                />
              </Col>
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Input
                  settings={FORM_INPUTS.zipCode}
                  defaultValue={props.payload.zipCode}
                  //isError={zipInputClasses}
                  onBlur={zipBlurHandler}
                  //isChanged={zipChangeHandler}
                  max={5}
                  ref={zipComponentRef}
                  //cols={"col-7"}
                />
              </Col>
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <DropDown
                  settings={FORM_INPUTS.country}
                  defaultValue={props.payload.country}
                  //isError={countryInputClasses}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Input
                  settings={FORM_INPUTS.email}
                  defaultValue={props.payload.email}
                  //isError={emailInputClasses}
                  //isChanged={emailInputChangeHandler}
                  onBlur={emailInputBlurHandler}
                  ref={emailComponentRef}
                />
              </Col>
              <Col md={12}>
                <Input
                  settings={FORM_INPUTS.phone}
                  defaultValue={props.payload.phone}
                  //isError={phoneInputClasses}
                  onBlur={phoneBlurHandler}
                  ref={phoneComponentRef}
                  //isChanged={phoneChangeHandler}
                />
              </Col>
            </Row>
            {payload.age !== "" && payload.age < 20 && (
              <Row>
                <Col md={4}>
                  <Input
                    settings={FORM_INPUTS.hsGradYear}
                    //isError={hsGradInputClasses}
                    ref={hsGradYearComponentRef}
                    onBlur={hsGradYearBlurHandler}
                  />
                </Col>
              </Row>
            )}
            {payload.age > 17 && (
              <Row>
                <Col>
                  <Checkbox settings={FORM_INPUTS.militaryStatus} />
                </Col>
              </Row>
            )}
            {payload.militaryStatus === true && (
              <Row>
                <Col md={12}>
                  <Input
                    settings={FORM_INPUTS.militaryReleaseDate}
                    //isError={milReleaseDateInputClasses}
                    ref={milReleaseDateComponentRef}
                    onBlur={milReleaseDateBlurHandler}
                  />
                </Col>
              </Row>
            )}
            <div className="post-form-disclaimer">
              <p className="submit-disclosure">
                By submitting this form, I agree that Universal Technical
                Institute, Inc., Custom Training Group, Inc. and their
                representatives may email, call, and / or text me with marketing
                messages about educational programs and services, as well as for
                school-related communications, at any phone number I provide,
                including a wireless number, using prerecorded calls or
                automated technology. I understand that my consent is not
                required to apply, enroll or make any purchase. I further
                understand and agree that I may instead call UTI at
                1-800-913-7524 to request admission information. I also
                understand I can receive a copy of my custom job report without
                being contacted by an admissions representative by visiting
                <Link
                  to="/noni"
                  className="active"
                  target="_self"
                  rel="noreferrer"
                >
                  &nbsp;here
                </Link>
                . I understand that if I do submit this form, I may unsubscribe
                within marketing emails or opt-out of text messages at any time
                by replying “STOP.” Standard text message and data rates may
                apply.
                <br></br>
                <br></br>
                By submitting this form, I understand and agree that all
                information provided is subject to UTI’s Privacy Policy
                available at &nbsp;
                <a
                  href="https://www.uti.edu/privacy-policy"
                  target="_blank"
                  rel="noopener"
                >
                  www.uti.edu/privacy-policy
                </a>{" "}
                and UTI's SMS Terms and Conditions available at{" "}
                <a
                  href="https://www.uti.edu/sms-terms-conditions"
                  target="_blank"
                  rel="noopener"
                >
                  www.uti.edu/sms-terms-conditions
                </a>
                .
              </p>
            </div>
            <div className="previousStep-btn" onClick={props.previousStep}>
              Previous Step
            </div>
          </Form>

          <div className="form-actions">
            <button
              className="cta-btn"
              //disabled={!stepIsValid}
              onClick={formSubmissionHandler}
            >
              Submit &amp; Continue &gt;
            </button>
          </div>

          {/* </div> */}
        </Col>
      </div>
    </Container>
  );
};

export default ContactForm;
