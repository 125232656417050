import React from "react";
import Row from "react-bootstrap/Row";
import "./aoiDisclaimer.scss";

const AoiDisclaimer = (prop) => {
  let render = [];
  var isEmpty = Object.is(prop.copy, "")
  if (!isEmpty) {
    return (
          <Row className="uti-disclaimer mx-0">
            <p
              dangerouslySetInnerHTML={{ __html: prop.copyNat ? prop.copyNat : "" }}
            ></p>
          </Row>
    );
    // if (prop.copy) {
    //   prop.copy.forEach((element, i) => {
    //     render.push(React.createElement("div", { key: i }, element));
    //   });
    // }
    // if (prop.nat === "false") {
    //   return (
    //     <Row className="uti-disclaimer mx-0">
    //       <p dangerouslySetInnerHTML={{ __html: prop.copy ? prop.copy : "" }}></p>
    //     </Row>
    //   );
    // } else if (prop.copyNat !== "") {
    //   return (
    //     <Row className="uti-disclaimer mx-0">
    //       <p
    //         dangerouslySetInnerHTML={{ __html: prop.copyNat ? prop.copyNat : "" }}
    //       ></p>
    //     </Row>
    //   );
    // } else {
    //   return <span></span>;
    // }
  } else {
    return null
  }
  
  

};

export default AoiDisclaimer;
