import React, { Component } from "react";
import TestChart from "../../minor/SVG/testChart";
import "./report.scss";
import Hero from "../../major/Hero/Hero";
import Footer from "../../major/Footer/Footer";
import SubFooter from "../../major/SubFooter/SubFooter";
//import './landing.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import { Link } from "react-router-dom";

class Report extends Component {
  state = {};
  render() {
    return (
      <Container fluid className="landing px-0 mx-0">
        <Hero />
        <div className="report container-fluid mx-0">
          <Row className="intro-section px-4">
            <p className="intro-text">
              {/* <Intro /> */}Kyle, <br></br>Thanks for your interest in learning about
              training for a career as a diesel technician! Below is your custom
              job report, which includes information about median salaries,
              demand and possible opportunities in the industry.*
            </p>
          </Row>
          <Row className="uti-disclaimer">
            *UTI is an educational institution and cannot guarantee employment
            or salary.
          </Row>

          <Row className="aoi-overview">
            <div className="aoi-title">
              <h1 className="">Diesel Technician Industry Overview </h1>
            </div>
            <div className="aoi-stats">
              <Col>
                <h1>28,000+</h1>
                <p className="aoi-stats-sub">
                  Average annual diesel tech job openings in the U.S.
                  <sup>1</sup>
                </p>
              </Col>
              <Col>
                <h1>45 Weeks</h1>
                <p className="aoi-stats-sub">
                  Length of Diesel Technology training at UTI<sup>2</sup>
                </p>
              </Col>
            </div>
            <div className="aoi-stats">
              <Col>
                <h1>$50,200</h1>
                <p className="aoi-stats-sub">
                  Median annual salary for diesel techs in the U.S.<sup>3</sup>
                </p>
              </Col>
              <Col>
                <h1>296,000+</h1>
                <p className="aoi-stats-sub">
                  Total diesel technician employment expected nationally by 2034
                </p>
              </Col>
              <p className="aoi-stats-disclaimer">
                *Not entry-level and is dependent on factors like experience,
                location and employer compensation.
              </p>
            </div>
            {/* <Row className=""></Row> */}
          </Row>
          <Row className="aoi-overview-disclaimer">
            <p>
              <sup>1</sup>For Bus and Truck Mechanics and Diesel Engine
              Specialists, the U.S. Bureau of Labor Statistics projects an
              annual average of 28,100 job openings between 2020 and 2030. Job
              openings include openings due to net employment changes and net
              replacements. See Table 1.10 Occupational separations and
              openings, projected 2020–30, U.S. Bureau of Labor Statistics,
              www.bls.gov, viewed November 18, 2021. UTI is an educational
              institution and cannot guarantee employment or salary. 2Some
              programs may require longer than one year to complete. 3Estimated
              annual median salary for Bus and Truck Mechanics and Diesel Engine
              Specialists in the U.S. Bureau of Labor Statistics’ Occupational
              Employment and Wages, May 2020. UTI is an educational institution
              and cannot guarantee employment or salary. UTI graduates’
              achievements may vary. Individual circumstances and wages depend
              on personal credentials and economic factors. Work experience,
              industry certifications, the location of the employer and their
              compensation programs affect wages. Entry-level salaries are
              lower. UTI programs prepare graduates for careers in industries
              using the provided training, primarily as diesel technicians. Some
              UTI graduates get jobs within their field of study in positions
              other than as a diesel truck technician, such as maintenance
              technician, locomotive technician and marine diesel technician.
              Salary information for the Commonwealth of Massachusetts: The
              average annual entry-level salary range for persons employed as
              Bus and Truck Mechanics and Diesel Engine Specialists (49-3031) in
              the Commonwealth of Massachusetts is $32,360 to $94,400
              (Massachusetts Labor and Workforce Development, May 2020 data,
              viewed January 19, 2022,
              https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#).
              North Carolina salary information: The U.S. Department of Labor
              estimate of hourly earnings of the middle 50% for skilled diesel
              technicians in North Carolina, published May 2021, is $23.20. The
              Bureau of Labor Statistics does not publish entry-level salary
              data. However, the 25th and 10th percentile of hourly earnings in
              North Carolina are $19.41 and $16.18, respectively. (Bureau of
              Labor Statistics, U.S. Department of Labor, Occupational
              Employment and Wages, May 2020. Bus and Truck Mechanics and Diesel
              Engine Specialists, viewed June 2, 2021.) 4The U.S. Bureau of
              Labor Statistics projects that total national employment for Bus
              and Truck Mechanics and Diesel Engine Specialists will be 296,800
              by 2030. See Table 1.2 Employment by detailed occupation, 2020 and
              projected 2030, U.S. Bureau of Labor Statistics, www.bls.gov,
              viewed November 18, 2021. UTI is an educational institution and
              cannot guarantee employment or salary.
            </p>
          </Row>
          <Row className="tech-industry">
            <p>
              Diesel technicians, also known as diesel mechanics, specialize in
              the maintenance and repair of diesel engines. They work on all
              types of equipment — from buses and trucks to bulldozers,
              agriculture equipment and even boats.
            </p>
            <h1>Diesel Technicial Industries</h1>
            <p>
              As diesel techs gain skills and experience, they can work in
              different settings. Some include:*
            </p>
            <ul>
              <li>Trucking and transportation</li>
              <li> Diesel automotive repair & maintenance</li>
              <li>Agriculture Locomotives Marine </li>
              <li>Commercial, military and manufacturing fleets</li>
              <li>Mobile diesel mechanic Construction</li>
              <li>Mining Power generation</li>
              <li>Service writing</li>
            </ul>
          </Row>
          <Row className="uti-disclaimer">
            *UTI programs prepare graduates for careers (primarily as diesel
            technicians) in industries using the provided training. Some UTI
            graduates get jobs within their field of study in positions other
            than as a diesel truck technician, such as maintenance technician,
            locomotive technician and marine diesel technician. UTI is an
            educational institution and cannot guarantee employment or salary.
          </Row>
          <Row className="tech-overview">
            <h1>Diesel Technician Jobs Data in Arizona</h1>
            <p>
              The total number of diesel technicians employed in Arizona was
              4,080 in 2020, according to the Bureau of Labor Statistics (BLS).
            </p>
            <i> </i>
            <h1>4,080</h1>
            <p>Employment for diesel technicians in Arizona*</p>
          </Row>
          <Row className="aoi-overview-disclaimer">
            <p>
              {" "}
              *U.S. Bureau of Labor Statistics estimate of total employment for
              Bus and Truck Mechanics and Diesel Engine Specialists, May 2020,
              U.S. Bureau of Labor Statistics, www.bls.gov, viewed June 2, 2021.
              UTI is an educational institution and cannot guarantee employment
              or salary.
            </p>
          </Row>
          <Row className="aoi-salary-state tech-industry">
            <h1>Salary for Diesel Technicians in Arizona</h1>
            <p>
              The chart below breaks down estimated annual salaries for diesel
              technicians in Arizona.1 Keep in mind that wages vary based on
              experience, employer, demand and cost of living in the area.
            </p>
            <TestChart />
            <Row className="salary-percentiles">
              <h2>
                Salary Percentiles<sup>2</sup>
              </h2>
              <p>
                <sup>1</sup>Not entry-level and is dependent on factors like
                experience, location and employer compensation.
              </p>
            </Row>
          </Row>
          <Row className="uti-disclaimer">
            <p>
              {" "}
              <sup>2</sup>Estimated annual salary by percentile for Bus and
              Truck Mechanics and Diesel Engine Specialists in the U.S. Bureau
              of Labor Statistics’ Occupational Employment and Wages, May 2020.
              UTI is an educational institution and cannot guarantee employment
              or salary. UTI graduates’ achievements may vary. Individual
              circumstances and wages depend on personal credentials and
              economic factors. Work experience, industry certifications, the
              location of the employer and their compensation programs affect
              wages. Entry-level salaries are lower. UTI programs prepare
              graduates for careers in industries using the provided training,
              primarily as diesel technicians. Some UTI graduates get jobs
              within their field of study in positions other than as a diesel
              truck technician, such as maintenance technician, locomotive
              technician and marine diesel technician. Salary information for
              the Commonwealth of Massachusetts: The average annual entry-level
              salary range for persons employed as Bus and Truck Mechanics and
              Diesel Engine Specialists (49-3031) in the Commonwealth of
              Massachusetts is $32,360 to $94,400 (Massachusetts Labor and
              Workforce Development, May 2020 data, viewed January 19, 2022,
              https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#).
              North Carolina salary information: The U.S. Department of Labor
              estimate of hourly earnings of the middle 50% for skilled diesel
              technicians in North Carolina, published May 2021, is $23.20. The
              Bureau of Labor Statistics does not publish entry-level salary
              data. However, the 25th and 10th percentile of hourly earnings in
              North Carolina are $19.41 and $16.18, respectively. (Bureau of
              Labor Statistics, U.S. Department of Labor, Occupational
              Employment and Wages, May 2020. Bus and Truck Mechanics and Diesel
              Engine Specialists, viewed June 2, 2021.)
            </p>
          </Row>
          <Row className="technician-tips tech-industry">
            <h1>Tips from a Diesel Technician</h1>
            <p>
              Hear from Universal Technical Institute grad Brandon Davis on what
              it’s like to be a diesel technician.*
            </p>
            <img alt="guy"></img>
            <p>
              “Without my education I wouldn’t be working here at Freightliner.
              It means everything.” <br></br>
              Brandon Davis | UTI Avondale graduate
            </p>
          </Row>
          <Row className="uti-disclaimer">
            *UTI is an educational institution and cannot guarantee employment
            or salary.
          </Row>
          <Row className="employer-testimonial tech-industry">
            <h1>Hear from a Diesel Employer</h1>
            <p>
              David Akins is a training manager for Freightliner and a proud
              employer of UTI grads. Find out why he recruits from the Diesel
              Technology program.*
            </p>
            <img alt="guy"></img>
            <p>
              “One of the biggest reasons we go to UTI on a regular basis is
              because of the quality of students we get from there.”
              <br></br>
              David Akins | Training Manager, Freightliner of Arizona
            </p>
          </Row>
          <Row className="uti-disclaimer">
            *UTI is an educational institution and cannot guarantee employment
            or salary.
          </Row>
          <Row className="tech-overview">
            <h1>National Diesel Technician Jobs Data </h1>
            <p>
              Diesel techs work across the nation, which means there could be
              opportunities whether you want to stay local or move to another
              state.<sup>1</sup>
            </p>
            <i></i>
            <h1>296,000+</h1>
            <p>
              Total diesel technician employment expected nationally by 2030
              <sup>2</sup>
            </p>
          </Row>
          <Row className="aoi-overview-disclaimer">
            <p>
              <sup>1</sup>UTI is an educational institution and cannot guarantee
              employment or salary. 2The U.S. Bureau of Labor Statistics
              projects that total national employment for Bus and Truck
              Mechanics and Diesel Engine Specialists will be 296,800 by 2030.
              See Table 1.2 Employment by detailed occupation, 2020 and
              projected 2030, U.S. Bureau of Labor Statistics, www.bls.gov,
              viewed November 18, 2021.
            </p>
          </Row>
          <Row className="behind-scenes tech-industry">
            <h1>
              Training for a Career: Go Behind the Scenes in UTI’s Diesel
              Technology Program
            </h1>
            <p>
              UTI’s 45-week Diesel Technology program includes hands-on and
              online instructor-led training to help prepare you for a career in
              the field.*
            </p>
            <img alt="guy"></img>
            <p>
              “UTI’s training is online and hands-on, which is great for the
              dealerships because we require online training as well.”
              <br></br>
              Fred Della Femina | Service Center Manager, Ray Catena Edison, NJ
            </p>
          </Row>
          <Row className="uti-disclaimer">
            *UTI is an educational institution and cannot guarantee employment
            or salary.
          </Row>
          <Row className="aoi-salary-state tech-industry">
            <h1>National Salaries for Diesel Technicians</h1>
            <p>
              Here’s a look at diesel tech salaries in the U.S., according to
              the BLS.<sup>1</sup>
            </p>
            <TestChart />
            <Row className="salary-percentiles">
              <h2>
                Salary Percentiles<sup>2</sup>
              </h2>
              <p>
                <sup>1</sup>Not entry-level and is dependent on factors like
                experience, location and employer compensation.
              </p>
            </Row>
          </Row>
          <Row className="uti-disclaimer">
            <p>
              {" "}
              <sup>2</sup>Estimated annual salary by percentile for Bus and
              Truck Mechanics and Diesel Engine Specialists in the U.S. Bureau
              of Labor Statistics’ Occupational Employment and Wages, May 2020.
              UTI is an educational institution and cannot guarantee employment
              or salary. UTI graduates’ achievements may vary. Individual
              circumstances and wages depend on personal credentials and
              economic factors. Work experience, industry certifications, the
              location of the employer and their compensation programs affect
              wages. Entry-level salaries are lower. UTI programs prepare
              graduates for careers in industries using the provided training,
              primarily as diesel technicians. Some UTI graduates get jobs
              within their field of study in positions other than as a diesel
              truck technician, such as maintenance technician, locomotive
              technician and marine diesel technician. Salary information for
              the Commonwealth of Massachusetts: The average annual entry-level
              salary range for persons employed as Bus and Truck Mechanics and
              Diesel Engine Specialists (49-3031) in the Commonwealth of
              Massachusetts is $32,360 to $94,400 (Massachusetts Labor and
              Workforce Development, May 2020 data, viewed January 19, 2022,
              https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#).
              North Carolina salary information: The U.S. Department of Labor
              estimate of hourly earnings of the middle 50% for skilled diesel
              technicians in North Carolina, published May 2021, is $23.20. The
              Bureau of Labor Statistics does not publish entry-level salary
              data. However, the 25th and 10th percentile of hourly earnings in
              North Carolina are $19.41 and $16.18, respectively. (Bureau of
              Labor Statistics, U.S. Department of Labor, Occupational
              Employment and Wages, May 2020. Bus and Truck Mechanics and Diesel
              Engine Specialists, viewed June 2, 2021.)
            </p>
          </Row>
          <Row className="highest-states">
            <h1>10 Highest-Paying States for Diesel Technicians</h1>
            <p>
              Below are the top 10 states with the highest median annual
              salaries for diesel technicians,* as well as where Arizona ranks
              in the national lineup:
            </p>
            <ol>
              <li>Alaska: $62,820</li>
              <li>Connecticut: $60,200</li>
              <li>Massachusetts: $60,150</li>
              <li>Hawaii: $60,020</li>
              <li>Washington: $59,500</li>
              <li>New Jersey: $59,030</li>
              <li>California: $58,980</li>
              <li>North Dakota: $56,990</li>
              <li>New York: $56,320</li>
              <li>Maryland: $55,180</li>
              <li><strong>Arizona: $47,830</strong></li>
            </ol>
          </Row>
          <Row className="uti-disclaimer">
            <p>
              {" "}
              <sup>2</sup>Estimated annual salary by percentile for Bus and
              Truck Mechanics and Diesel Engine Specialists in the U.S. Bureau
              of Labor Statistics’ Occupational Employment and Wages, May 2020.
              UTI is an educational institution and cannot guarantee employment
              or salary. UTI graduates’ achievements may vary. Individual
              circumstances and wages depend on personal credentials and
              economic factors. Work experience, industry certifications, the
              location of the employer and their compensation programs affect
              wages. Entry-level salaries are lower. UTI programs prepare
              graduates for careers in industries using the provided training,
              primarily as diesel technicians. Some UTI graduates get jobs
              within their field of study in positions other than as a diesel
              truck technician, such as maintenance technician, locomotive
              technician and marine diesel technician. Salary information for
              the Commonwealth of Massachusetts: The average annual entry-level
              salary range for persons employed as Bus and Truck Mechanics and
              Diesel Engine Specialists (49-3031) in the Commonwealth of
              Massachusetts is $32,360 to $94,400 (Massachusetts Labor and
              Workforce Development, May 2020 data, viewed January 19, 2022,
              https://lmi.dua.eol.mass.gov/lmi/OccupationalEmploymentAndWageSpecificOccupations#).
              North Carolina salary information: The U.S. Department of Labor
              estimate of hourly earnings of the middle 50% for skilled diesel
              technicians in North Carolina, published May 2021, is $23.20. The
              Bureau of Labor Statistics does not publish entry-level salary
              data. However, the 25th and 10th percentile of hourly earnings in
              North Carolina are $19.41 and $16.18, respectively. (Bureau of
              Labor Statistics, U.S. Department of Labor, Occupational
              Employment and Wages, May 2020. Bus and Truck Mechanics and Diesel
              Engine Specialists, viewed June 2, 2021.)
            </p>
          </Row>
          <Row className="uti-snapshot">
            <h1>Start Your Diesel Training</h1>
            <p>
              With more than <strong>$15 million in scholarships & grants available* and
              classes starting every 3 to 6</strong> weeks, it’s a great time to start
              your training at UTI.{" "}
            </p>
            <Row>
              <div className="funky-shape"></div>
              <h2>$15,000,000</h2>
              <p className="uti-points-a"><strong>in Scholarships and Grants Available to Those Who Qualify</strong></p>
              </Row>
              <Row >
              <div className="funky-shape"></div>
              <p className="uti-points-b"><strong>Classes Starting Every</strong></p>
              <h2 className="alter-h2">3 to 6 Weeks</h2>
              </Row>
          </Row>
          <Row className="uti-disclaimer">
          *Figure is for 2022. Financial aid, scholarships and grants are available to those who qualify. Awards vary due to specific conditions, criteria and state.
          </Row>
          <div className="links-for-you">
            <h1>Let Us Show You Jobs in Demand and How You Can Get Trained in Less Than a Year.*</h1>
        <div
          className="cta-btn "
        >
          <p className="btn-text">SCHEDULE A CAMPUS TOUR </p>
        </div>
        <br></br>
        <div
          className="cta-btn "
        >
          <p className="btn-text">SPEAK WITH YOUR REP NOW </p>
        </div>
          </div>
          <Row className="uti-disclaimer">
            <p>
            *UTI is an educational institution and cannot guarantee employment or salary. Some programs may require longer than one year to complete. For program outcome information and other disclosures, visit www.uti.edu/disclosures.
          
            </p>
          </Row>
          <Row className="tech-industry">
            <div pathname="#">Share the Report</div>
            <div>Download PDF</div>
          </Row>
        </div>
        <Footer />
        <SubFooter />
      </Container>
    );
  }
}

export default Report;
