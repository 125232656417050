import React, { Component } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "./footer.scss";

class Footer extends Component {
  state = {
    info: [],
  };

  render() {
    return (
      <Container fluid className="footer mx-0 px-0">
        <div className="footer-text">
          <p>
            <sup>1</sup>Some programs may require longer than one year to
            complete.
          </p>

          <p>
            <sup>2</sup>UTI is an educational institution and cannot guarantee
            employment or salary. For program outcome information and other
            disclosures, visit
            <a href="https://www.uti.edu/disclosures">
              &nbsp;www.uti.edu/disclosures
            </a>
            .
          </p>
          {/* <p>
            <sup>3</sup>For Automotive Service Technicians and Mechanics, the
            U.S. Bureau of Labor Statistics projects an annual average of 61,700
            job openings between 2019 and 2029. Job openings include openings
            due to net employment changes and net replacements. See Table 1.10
            Occupational separations and openings, projected 2019–29, U.S.
            Bureau of Labor Statistics,{" "}
            <a href="https://www.bls.gov" target="_blank">
              www.bls.gov
            </a>
            , viewed June 3, 2021. UTI is an educational institution and cannot
            guarantee employment or salary.
          </p>
          <p>
            <sup>4</sup>Estimated annual median salary for Automotive Service
            Technicians and Mechanics in the U.S. Bureau of Labor Statistics’
            Occupational Employment and Wages, May 2020. UTI is an educational
            institution and cannot guarantee employment or salary. UTI
            graduates’ achievements may vary. Individual circumstances and wages
            depend on personal credentials and economic factors. Work
            experience, industry certifications, the location of the employer
            and their compensation programs affect wages. Entry-level salaries
            may be lower. UTI programs prepare graduates for careers in
            industries using the provided training, primarily as automotive
            technicians. Some UTI graduates get jobs within their field of study
            in positions other than as a technician, such as service writer,
            smog inspector and parts manager. Salary information for the
            Commonwealth of Massachusetts: The average annual entry-level salary
            range for persons employed as Automotive Service Technicians and
            Mechanics in the Commonwealth of Massachusetts (49-3023) is $30,308
            to $53,146 (Massachusetts Labor and Workforce Development, May 2019
            data, viewed June 2, 2021,{" "}
            <a href="https://bit.ly/3zeDATZ" target="_blank">
              https://bit.ly/3zeDATZ
            </a>
            ). North Carolina salary information: The U.S. Department of Labor
            estimate of hourly earnings of the middle 50% for skilled automotive
            technicians in North Carolina, published May 2021, is $20.59. The
            Bureau of Labor Statistics does not publish entry-level salary data.
            However, the 25th and 10th percentile of hourly earnings in North
            Carolina are $14.55 and $11.27, respectively. (Bureau of Labor
            Statistics, U.S. Department of Labor, Occupational Employment and
            Wages, May 2020. Automotive Service Technicians and Mechanics,
            viewed June 2, 2021.)
          </p> */}
          {/* <p>
            For program outcome information and other disclosures, visit{" "}
            <Link
              to="https://www.uti.edu/disclosures"
              className="active"
              target="_blank"
              rel="noreferrer"
            >
              www.uti.edu/disclosures.
            </Link>
          </p> */}
        </div>
      </Container>
    );
  }
}

export default Footer;
