import React from "react";
//import Row from "react-bootstrap/Row";
import "./helpfulLinks.scss";

const HelpfulLinks = (prop) => {
  return (
    <div className="links-for-you ">
      <h1>
        Let Us Show You Jobs in Demand and How You Can Get Trained in Less Than
        a Year.*
      </h1>
      <div className="btn-div">
        <div className="cta-btn ">
          <a href="https://www.uti.edu/locations/campus-tours">
            <p className="btn-text">SCHEDULE A CAMPUS TOUR</p>
          </a>
        </div>
        <br></br>
        {/* <div
  className="cta-btn "
>
  <p className="btn-text">SPEAK WITH YOUR REP NOW </p>
</div> */}
      </div>
    </div>
  );
};

export default HelpfulLinks;
