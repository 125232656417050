import React from "react";
import Col from "react-bootstrap/Col";
import "./jobOverview.scss";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const JobOverview = (prop) => {
  return (
    <div className="aoi-overview p-5 mx-0">
      <div className="aoi-title">
        <h1 className="">{prop.copy ? prop.copy.title : ""}</h1>
      </div>
      <div className="stats-container">
        <div className="aoi-stats">
          <Col>
            <div className="stat">
              <h2>
                {numberWithCommas(parseInt(prop.data.averageNationalJobs))}+
              </h2>
              <p
                className="aoi-stats-sub"
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.avg_annual : "",
                }}
              ></p>
            </div>
          </Col>
          <Col>
            <div className="stat">
              <h2>
                ${numberWithCommas(parseInt(prop.data.medianAnnualSalary))}
              </h2>
              <p
                className="aoi-stats-sub"
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.ann_salary : "",
                }}
              ></p>
              <p
                className="aoi-stats-sub-italics"
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.disclaimer : "",
                }}
              ></p>
            </div>
          </Col>
        </div>
        <div className="aoi-stats">
          <Col>
            <div className="stat">
              <h2>{prop.copy ? prop.copy.length : ""}</h2>
              <p
                className="aoi-stats-sub"
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.weeks : "",
                }}
              ></p>
            </div>
          </Col>
          <Col>
            <div className="stat">
              <h2>
                {numberWithCommas(parseInt(prop.data.nationalEmployment) + "+")}
              </h2>
              <p
                className="aoi-stats-sub"
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.total_emp : "",
                }}
              ></p>
            </div>
          </Col>
        </div>
      </div>
      {/* <Row className=""></Row> */}
    </div>
  );
};

export default JobOverview;
