import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TestChart from "../../SVG/testChart";
import "./nationalSalary.scss";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const NationalSalary = (prop) => {
  return (
    <Row className="national-salary tech-industry mx-0">
      <Col md={12}>
        <h1>
          {prop.copy ? prop.copy.title : ""}
          {/* {prop.nat === "false" ? " in " + prop.data.userState : " "} */}
        </h1>
      </Col>

      <Col md={6}>
        <p
          className="text"
          dangerouslySetInnerHTML={{
            __html: prop.copy ? prop.copy.text : "",
          }}
        ></p>
      </Col>

      <Col md={6}>
        <div className="chart-holder">
          <Row>
            <Col xs={4} sm={4} md={4} col={4} className="text-center">
              <p className="first">
                $
                {prop.data
                  ? numberWithCommas(
                      parseInt(prop.data.nationalSalaryPercetile[25])
                    )
                  : ""}
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} col={4} className="text-center">
              <p className="second">
                $
                {prop.data
                  ? numberWithCommas(
                      parseInt(prop.data.nationalSalaryPercetile[50])
                    )
                  : ""}
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} col={4} className="text-center">
              <p className="third">
                $
                {prop.data
                  ? numberWithCommas(
                      parseInt(prop.data.nationalSalaryPercetile[75])
                    )
                  : ""}
              </p>
            </Col>
          </Row>

          <TestChart />
        </div>

        <Row className="salary-percentiles">
          <h2>
            Salary Percentiles<sup>2</sup>
          </h2>
        </Row>

        <p
          dangerouslySetInnerHTML={{
            __html: prop.copy ? prop.copy.disclaimer : "",
          }}
        ></p>
      </Col>
    </Row>
  );
};

export default NationalSalary;
