import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { FormContext } from "../../context/formContext";

const Checkbox = (props) => {
  const payload = useContext(FormContext);
  let {
    fieldType = "checkbox",
    placeholder,
    label,
    className,
    controlId,
  } = props.settings;

  return (
    <Form.Group controlId={`form.${controlId}`}>
      <Form.Check
      type="checkbox"
      id={controlId}
        className={className}
        //placeholder={placeholder}
        onChange={(e) => {
          payload.updateState(controlId, e.target.checked);
        }}
        label={label}
      />
      {/* <Form.Label className="form-label-text">{label}</Form.Label> */}
    </Form.Group>
  );
};
export default Checkbox;
