import React from "react";
import Row from "react-bootstrap/Row";
import "./utiDisclaimer.scss";

const UtiDisclaimer = (prop) => {
  // let render = [];
  // if (prop.copy) {
  //   prop.copy.forEach((element, i) => {
  //     render.push(React.createElement("div", {key: i}, element));
  //   });
  // }
  if (prop.nat === "false") {
    return (
      <Row className="uti-disclaimer mx-0">
        <p dangerouslySetInnerHTML={{ __html: prop.copy ? prop.copy : "" }}></p>
      </Row>
    );
  } else if (prop.copyNat !== "") {
    return (
      <Row className="uti-disclaimer mx-0">
        <p
          dangerouslySetInnerHTML={{ __html: prop.copyNat ? prop.copyNat : "" }}
        ></p>
      </Row>
    );
  } else {
    return null;
  }
};

export default UtiDisclaimer;
