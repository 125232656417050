import React from "react";
import Row from "react-bootstrap/Row";
import "./jobsDisclaimer.scss";

const JobsDisclaimer = (prop) => {
  // let render = [];
  // if (prop.copy) {
  //   prop.copy.forEach((element, i) => {
  //     render.push(React.createElement("span", {key: i}, element));
  //   });
  // }.
  var isEmpty = Object.is(prop.copy, "")
  if (!isEmpty) {
    return (
      <Row className="aoi-overview-disclaimer p-3 mx-0">
        <p dangerouslySetInnerHTML={{ __html: prop.copy ? prop.copy : "" }}></p>
      </Row>
    );
  } else {
    return null
  }

  
};

export default JobsDisclaimer;
