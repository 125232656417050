import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./techList.scss";

const techList = (prop) => {
  let listRender = [];
  let listRender2 = [];
  if (prop.copy) {
    prop.copy.list.forEach((element, i) => {
      i < 6
        ? listRender.push(<li key={i}>{element}</li>)
        : listRender2.push(<li key={i}>{element}</li>);
    });
  }
  return (
    <Row className="tech-industry mx-0">
      <p className="pt-5">{prop.copy ? prop.copy.description : ""}</p>
      <h1 className="pt-5 text-center">{prop.copy ? prop.copy.title : ""}</h1>
      <p className="pt-2 text-center">{prop.copy ? prop.copy.sub_desc : ""}</p>

      <Col md={{ span: 3, offset: 3 }}>
        {" "}
        <ul>{listRender}</ul>
      </Col>
      <Col md={{ span: 3, offset: 1 }}>
        <ul>{listRender2}</ul>
      </Col>
    </Row>
  );
};

export default techList;
