import React, { Component, useContext } from "react";
//import { Link } from 'react-router-dom';
import Hero from "../../major/Hero/Hero";
// import FormController from "../../major/FormController/FormController";
import { Row, Col, Container } from "react-bootstrap";
import OptOutForm from "../../major/OptOutForm/OptOutForm";
import Footer from "../../major/Footer/Footer";
import SubFooter from "../../major/SubFooter/SubFooter";
import "./Noni.scss";
//import '../sass/base/main.scss'

import FormContextProvidor, { FromContext } from "../../context/formContext";
import PostForm from "../../major/PostForm/PostForm";
//import Wrapper from "../../major/Wrapper/Wrapper";

class Out extends Component {
  render() {
    return (
      <div>
        <Container fluid className="landing hero">
          <Container>
            <Row>
              <Col lg={7}>
                <Row>
                  <Col md={12}>
                    <div className="hero">
                      <div className="subText">Available Now</div>
                      <h1 className="headText">
                        {" "}
                        Get Your 2023 Custom <br />
                        Jobs Report
                      </h1>
                      <div className="subText">At No Cost</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={5} md={12} sm={12}>
                <div className="the-form">
                  <OptOutForm />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container>
          <Row>
            <Col md={6}>
              <PostForm noni="true" />
            </Col>
          </Row>
        </Container>
        <Footer />
        <SubFooter />
      </div>
      // <Container fluid className="landing px-0">
      //   {/* <Row>
      //     <h1 className="form-text-headline">{headLine}</h1>
      //   </Row> */}
      //   <Hero />
      //   <OptOutForm />
      //   <PostForm noni="true" />
      //   <Footer />
      //   <SubFooter />
      // </Container>
    );
  }
}

export default Out;
