import React, { useContext } from "react";
import AOI_DATA from "../../data/aoi";
import AOISelect from "../AOISelect/AOISelect";
import ContactForm from "../ContactForm/ContactForm";
import ExtraInfo from "../ExtraInfoForm/ExtraInfo";
import SubmissionPage from "../SubmissionPage/SubmissionPage";
import { Row, Col, Container } from "react-bootstrap";
import HELPER_FUNCTIONS from "../../helpers/zipCodeLookup";
import { FormContext } from "../../context/formContext";
import axios from "axios";
import ENDPOINT from "../../data/dev";
import dataLayerHelper from "../../helpers/dataLayer";

require("dotenv").config();

async function submitJobReport(payload) {
  payload["state"] = HELPER_FUNCTIONS.getState(payload.zipCode);

  if (!dataLayerHelper.dlEventCheck("CJR-submitted", "true")) {
    window.dataLayer.push({ event: "CJR-submitted", formId: "UTIForm042" });
  }

  await axios.post(ENDPOINT.endpoint(), payload).then((retData) => {
    const returnHubData = retData.data;
    payload.updateState(
      "reportId",
      "https://cjr.uti.edu/report/" + returnHubData.reportId
    );

    fetch("/api/sms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        payload,
        returnHubData,
      }),
    })
      .then(
        fetch("/api/mail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload,
            returnHubData,
          }),
        })
      )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  });
}

const FormController = () => {
  const prevScroll = () => {
    javascript: window.scrollTo(0, 0);
    setStep(step - 1);
  };

  const payload = useContext(FormContext);

  const [step, setStep] = React.useState(1);
  const [payloadReady, setPayloadReady] = React.useState(false);

  //const inquiryPayload = useContext(InquiryPayloadContext);
  //FormContextProvider.testContextFunc();

  function initializePayload(payload) {
    // console.log("initializePayload()");
    // console.log(payload);
    payload.updateState("step", step);
    payload.initPayload();

    /// GTM
    window.dataLayer = window.dataLayer || [];

    if (!dataLayerHelper.dlEventCheck("form-start", "UTIForm042")) {
      window.dataLayer.push({
        event: "form-start",
        formId: "UTIForm042",
      });
    }
  }

  async function submitInquiry(step, payload) {
    setPayloadReady(false);
    // console.log(`submitInquiry(${step}, ${payload})`);
    // console.log(payload.payload);
    setStep(step + 1);
    if (step === 3) {
      await submitJobReport(payload);
    }

    payload.updateState("step", step);
    await payload.finalizePayload();

    if (!/localhost/.test(document.domain)) {
      if (!dataLayerHelper.dlEventCheck("form-success", "UTIForm042")) {
        window.dataLayer.push({ event: "form-success", formId: "UTIForm042" });
      }
    }

    postData(payload);
  }

  function postData(payload) {
    // console.log("postData()");
    // if (payload.EWC_Accept) {
    axios.post(ENDPOINT.lgEndpoint(), payload.payload).then(() => {
      // console.log("posted to LG");
    });
  }

  //console.log(process.env.NODE_ENV);
  let AOIList = AOI_DATA.AOI;

  let selectedAoi = payload.AOI;

  // console.log("selectedaoi " + selectedAoi);
  // console.log("payloadaoi: " + payload.AOI);
  let formStep = "";

  let headLine = (
    <div className="headline">
      <p>In Three Simple Clicks We'll </p>
      <h2 className="form-text-headline">Customize Your Report</h2>
      <p>And Send It Right Away</p>
    </div>
  );

  let progressBar = (
    <Col className="progressBar-container">
      <div className="progressOv">
        <Col className="item prog1"></Col>
        <Col className={`item prog2 ` + (step >= 2 ? "progressOv" : "")}></Col>
        <Col className={`item prog3 ` + (step >= 3 ? "progressOv" : "")}></Col>
      </div>
      <Row className="form-step-text">Step {step} of 3</Row>
    </Col>
  );

  if (step === 1) {
    formStep = (
      <Row className="aoi-dropdown">
        <Col className="selection">
          {/* <p className="prompt-text">What program are you interested in?</p> */}
          <AOISelect
            progressStep={() => {
              setStep(step + 1);
              initializePayload(payload);
            }}
            AOIS={AOIList}
            payload={payload}
          />
        </Col>
      </Row>
    );
  }
  if (step === 2) {
    headLine = (
      <div className="headline">
        <h2 className="form-text-headline">
          Get Your Custom <br /> Jobs Report
        </h2>
      </div>
    );
    formStep = (
      <ContactForm
        progressStep={() => {
          setStep(step + 1);
          submitInquiry(step, payload);
        }}
        previousStep={prevScroll}
        payload={payload}
      />
    );
  }
  if (step === 3) {
    headLine = (
      <div className="headline">
        <h2 className="form-text-headline">
          Get Your Custom <br /> Jobs Report
        </h2>
      </div>
    );
    formStep = (
      <ExtraInfo
        previousStep={prevScroll}
        submit={() => {
          // setStep(step + 1);
          submitInquiry(step, payload);
          //submitJobReport(payload);
        }}
      />
    );
  }
  if (step >= 4) {
    headLine = (
      <div className="headline">
        <h2 className="form-text-headline">Check Your Inbox</h2>
      </div>
    );
    //console.log("state payload" + payload.state.state);
    progressBar = "";
    formStep = <SubmissionPage aoi={selectedAoi} zip={payload.zipCode} />;
  }

  // if (step === 5) {
  //   formStep = (
  //     <OptOutForm
  //       previousStep={() => setStep(step - 3)}
  //       submit={() => {
  //         submitJobReport(payload);
  //         //dont submit to LCS
  //         setStep(step - 1);
  //       }}
  //     />
  //   );
  // }

  return (
    <Container className="form-controller">
      <div className="form-wrapper pad-gutter">
        <Row>
          <Col>{headLine}</Col>
        </Row>
        {progressBar}
        {formStep}
      </div>
    </Container>
  );
};

export default FormController;
