import React from "react";
import "./nationalAoiInfo.scss";

import icon from "../../../media/svg/truck-icon.png";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const NationalAoiInfo = (prop) => {
  return (
    <div className="national-info mx-0">
      <h1>{prop.copy ? prop.copy.title : ""}</h1>
      <p>
        {prop.copy ? prop.copy.text : ""}
        <sup>1</sup>
      </p>
      <div className="text-center">
        <img
          src={icon}
          width={100}
          className="text-center pb-4"
          alt={prop.copy ? prop.copy.title : ""}
        />
      </div>
      <h1 className="nat-aoi">
        {prop.data
          ? numberWithCommas(parseInt(prop.data.nationalEmployment) + "+")
          : ""}
      </h1>
      <p className="nat-sub">
        {prop.copy ? prop.copy.figure : ""}
        <sup>2</sup>
      </p>
    </div>
  );
};

export default NationalAoiInfo;
