import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TestChart from "../../SVG/testChart";
import "./aoiSalaryChart.scss";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const AoiSalaryChart = (prop) => {
  if (prop.nat === "false") {
    return (
      <Row className="aoi-salary-state tech-industry mx-0">
        <Col md={12}>
          <h1>
            {prop.copy ? prop.copy.title : ""} {prop.data.userState}
          </h1>
        </Col>

        <Col md={6}>
          <p className="text">
            {prop.copy ? prop.copy.text : ""} {prop.data.userState}
            <sup>1</sup>. {prop.copy ? prop.copy.text_two : ""}
          </p>
        </Col>

        <Col md={6}>
          <div className="chart-holder">
            <Row>
              <Col xs={4} sm={4} md={4} className="text-center">
                <p className="first">
                  $
                  {prop.data
                    ? numberWithCommas(
                        parseInt(prop.data.stateSalaryPercetile[25])
                      )
                    : ""}
                </p>
              </Col>
              <Col xs={4} sm={4} md={4} className="text-center">
                <p className="second">
                  $
                  {prop.data
                    ? numberWithCommas(
                        parseInt(prop.data.stateSalaryPercetile[50])
                      )
                    : ""}
                </p>
              </Col>
              <Col xs={4} sm={4} md={4} className="text-center">
                <p className="third">
                  $
                  {prop.data
                    ? numberWithCommas(
                        parseInt(prop.data.stateSalaryPercetile[75])
                      )
                    : ""}
                </p>
              </Col>
            </Row>

            <TestChart />
          </div>

          <Row className="salary-percentiles">
            <h2>
              Salary Percentiles<sup>2</sup>
            </h2>
          </Row>

          <p
            dangerouslySetInnerHTML={{
              __html: prop.copy ? prop.copy.disclaimer : "",
            }}
          ></p>
        </Col>
      </Row>
    );
  } else {
    return <span></span>;
  }
};

export default AoiSalaryChart;
