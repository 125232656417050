//import AOIList from "./aoi.js";

//export default 
module.exports.AOIs = {
  // fieldType: "dropdown",
  label: "Areas of Interest",
  className: "select-field",
  //controlId needs to match FormContext state property
  controlId: "AOI",
  placeholder: "Select an Area of Interest",
  options: {
    type: "list",
    list: [
      {
        AreaOfInterestCode: "AU",
        AreaOfInterestName: "Automotive",
        school: "Universal Technical Institute",
        campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
        value: "AU",
        label: "Automotive"
      },
      {
        AreaOfInterestCode: "CNC",
        AreaOfInterestName: "CNC Machining",
        school: "Universal Technical Institute",
        campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
        value: "CNC",
        label: "CNC Machining"
      },
      {
        AreaOfInterestCode: "CR",
        AreaOfInterestName: "Collision Repair",
        school: "Universal Technical Institute",
        campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
        value: "CR",
        label: "Collision Repair"
      },
      {
        AreaOfInterestCode: "DI",
        AreaOfInterestName: "Diesel",
        school: "Universal Technical Institute",
        campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
        value: "DI",
        label: "Diesel"
      },
      {
        AreaOfInterestCode: "WELD",
        AreaOfInterestName: "Welding",
        school: "Universal Technical Institute",
        campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
        value: "WELD",
        label: "Welding" 
      },
    ]
  },
};
module.exports.firstName = {
  fieldType: "text",
  label: "First Name",
  className: "name-field",
  controlId: "firstName",
  placeholder: "First Name",
};
module.exports.lastName = {
  fieldType: "text",
  label: "Last Name",
  className: "name-field",
  controlId: "lastName",
  placeholder: "Last Name",
};
module.exports.age = {
  // fieldType: "dropdown",
  label: "Age",
  className: "select-field",
  controlId: "age",
  placeholder: "Age",
  options: {
    type: "range",
    minVal: 14,
    maxVal: 80,
  },
};
module.exports.zipCode = {
  fieldType: "text",
  label: "Zip",
  className: "zip-field",
  controlId: "zipCode",
  placeholder: "90210",
  options: {
    validationType: "regex",
    regx: /\d{5}(\-\d{4})?/
  }
};
module.exports.country = {
  // fieldType: "dropdown",
  label: "Country",
  className: "select-field",
  controlId: "country",
  placeholder: "United States",

  options: {
    type: "list",
    list: [
      { value: "US", label: "United States" },
      { value: "AF", label: "Afghanistan" },
      { value: "AL", label: "Albania" },
      { value: "DZ", label: "Algeria" },
      { value: "AS", label: "American Samoa" },
      { value: "AD", label: "Andorre" },
      { value: "AO", label: "Angola" },
      { value: "AI", label: "Anguilla" },
      { value: "AQ", label: "Antarctica" },
      { value: "AG", label: "Antigua And Barbuda" },
      { value: "AR", label: "Argentina" },
      { value: "AM", label: "Armenia" },
      { value: "AW", label: "Aruba" },
      { value: "AU", label: "Australia" },
      { value: "AZ", label: "Azerbaijan" },
      { value: "BS", label: "Bahamas" },
      { value: "BH", label: "Bahrain" },
      { value: "BD", label: "Bangladesh" },
      { value: "BB", label: "Barbados" },
      { value: "BY", label: "Belarus" },
      { value: "BZ", label: "Belize" },
      { value: "BJ", label: "Benin" },
      { value: "BM", label: "Bermuda" },
      { value: "BT", label: "Bhutan" },
      { value: "BO", label: "Bolivia" },
      { value: "BA", label: "Bosnia And Herzegovina" },
      { value: "BW", label: "Botswana" },
      { value: "BV", label: "Bouvet Island" },
      { value: "BR", label: "Brazil" },
      { value: "IO", label: "British Indian Ocean Territory" },
      { value: "BN", label: "Brunei Darussalam" },
      { value: "BF", label: "Burkina Faso" },
      { value: "BI", label: "Burundi" },
      { value: "KH", label: "Cambodia" },
      { value: "CM", label: "Cameroon" },
      { value: "CA", label: "Canada" },
      { value: "CV", label: "Cape Verde" },
      { value: "KY", label: "Cayman Islands" },
      { value: "CF", label: "Central African Republic" },
      { value: "TD", label: "Chad" },
      { value: "CL", label: "Chile" },
      { value: "CN", label: "China" },
      { value: "CX", label: "Christmas Island" },
      { value: "CC", label: "Cocos (Keeling) Islands" },
      { value: "CO", label: "Colombia" },
      { value: "KM", label: "Comoros" },
      { value: "CG", label: "Congo" },
      { value: "CD", label: "Congo, The Democratic Republic Of The" },
      { value: "CK", label: "Cook Islands" },
      { value: "CR", label: "Costa Rica" },
      { value: "CI", label: "Cote D'Ivoire" },
      { value: "CU", label: "Cuba" },
      { value: "DJ", label: "Djibouti" },
      { value: "DM", label: "Dominica" },
      { value: "DO", label: "Dominican Republic" },
      { value: "TP", label: "East Timor" },
      { value: "EC", label: "Ecuador" },
      { value: "EG", label: "Egypt" },
      { value: "SV", label: "El Salvador" },
      { value: "GQ", label: "Equatorial Guinea" },
      { value: "ER", label: "Eritrea" },
      { value: "ET", label: "Ethiopia" },
      { value: "FK", label: "Falkland Islands (Malvinas)" },
      { value: "FO", label: "Faroe Islands" },
      { value: "FJ", label: "Fiji" },
      { value: "GF", label: "French Guiana" },
      { value: "PF", label: "French Polynesia" },
      { value: "TF", label: "French Southern Territories" },
      { value: "GA", label: "Gabon" },
      { value: "GM", label: "Gambia" },
      { value: "GE", label: "Georgia" },
      { value: "GH", label: "Ghana" },
      { value: "GI", label: "Gibraltar" },
      { value: "GL", label: "Greenland" },
      { value: "GD", label: "Grenada" },
      { value: "GP", label: "Guadeloupe" },
      { value: "GU", label: "Guam" },
      { value: "GT", label: "Guatemala" },
      { value: "GN", label: "Guinea" },
      { value: "GW", label: "Guinea-Bissau" },
      { value: "GY", label: "Guyana" },
      { value: "HT", label: "Haiti" },
      { value: "HM", label: "Heard Island And Mcdonald Islands" },
      { value: "VA", label: "Holy See (Vatican City State)" },
      { value: "HN", label: "Honduras" },
      { value: "HK", label: "Hong Kong" },
      { value: "IS", label: "Iceland" },
      { value: "IN", label: "India" },
      { value: "ID", label: "Indonesia" },
      { value: "IR", label: "Iran, Islamic Republic Of" },
      { value: "IQ", label: "Iraq" },
      { value: "IL", label: "Israel" },
      { value: "JM", label: "Jamaica" },
      { value: "JP", label: "Japan" },
      { value: "JO", label: "Jordan" },
      { value: "KZ", label: "Kazakstan" },
      { value: "KE", label: "Kenya" },
      { value: "KI", label: "Kiribati" },
      { value: "KP", label: "Korea, Democratic People's Republic Of" },
      { value: "KR", label: "Korea, Republic Of" },
      { value: "KW", label: "Kuwait" },
      { value: "KG", label: "Kyrgyzstan" },
      { value: "LA", label: "Lao People's Democratic Republic" },
      { value: "LB", label: "Lebanon" },
      { value: "LS", label: "Lesotho" },
      { value: "LR", label: "Liberia" },
      { value: "LY", label: "Libyan Arab Jamahiriya" },
      { value: "LI", label: "Liechtenstein" },
      { value: "MO", label: "Macau" },
      { value: "MK", label: "Macedonia, Former Yugoslav Republic Of" },
      { value: "MG", label: "Madagascar" },
      { value: "MW", label: "Malawi" },
      { value: "MY", label: "Malaysia" },
      { value: "MV", label: "Maldives" },
      { value: "ML", label: "Mali" },
      { value: "MH", label: "Marshall Islands" },
      { value: "MQ", label: "Martinique" },
      { value: "MR", label: "Mauritania" },
      { value: "MU", label: "Mauritius" },
      { value: "YT", label: "Mayotte" },
      { value: "MX", label: "Mexico" },
      { value: "FM", label: "Micronesia, Federated States Of" },
      { value: "MD", label: "Moldova, Republic Of" },
      { value: "MC", label: "Monaco" },
      { value: "MN", label: "Mongolia" },
      { value: "MS", label: "Montserrat" },
      { value: "MA", label: "Morocco" },
      { value: "MZ", label: "Mozambique" },
      { value: "MM", label: "Myanmar" },
      { value: "NA", label: "Namibia" },
      { value: "NR", label: "Nauru" },
      { value: "NP", label: "Nepal" },
      { value: "AN", label: "Netherlands Antilles" },
      { value: "NC", label: "New Caledonia" },
      { value: "NZ", label: "New Zealand" },
      { value: "NI", label: "Nicaragua" },
      { value: "NE", label: "Niger" },
      { value: "NG", label: "Nigeria" },
      { value: "NU", label: "Niue" },
      { value: "NF", label: "Norfolk Island" },
      { value: "MP", label: "Northern Mariana Islands" },
      { value: "NO", label: "Norway" },
      { value: "OM", label: "Oman" },
      { value: "PK", label: "Pakistan" },
      { value: "PW", label: "Palau" },
      { value: "PS", label: "Palestinian Territory, Occupied" },
      { value: "PA", label: "Panama" },
      { value: "PG", label: "Papua New Guinea" },
      { value: "PY", label: "Paraguay" },
      { value: "PE", label: "Peru" },
      { value: "PH", label: "Philippines" },
      { value: "PN", label: "Pitcairn" },
      { value: "PR", label: "Puerto Rico" },
      { value: "QA", label: "Qatar" },
      { value: "RE", label: "Reunion" },
      { value: "RU", label: "Russian Federation" },
      { value: "RW", label: "Rwanda" },
      { value: "SH", label: "Saint Helena" },
      { value: "KN", label: "Saint Kitts And Nevis" },
      { value: "LC", label: "Saint Lucia" },
      { value: "PM", label: "Saint Pierre And Miquelon" },
      { value: "VC", label: "Saint Vincent And The Grenadines" },
      { value: "WS", label: "Samoa" },
      { value: "SM", label: "San Marino" },
      { value: "ST", label: "Sao Tome And Principe" },
      { value: "SA", label: "Saudi Arabia" },
      { value: "SN", label: "Senegal" },
      { value: "SC", label: "Seychelles" },
      { value: "SL", label: "Sierra Leone" },
      { value: "SG", label: "Singapore" },
      { value: "SB", label: "Solomon Islands" },
      { value: "SO", label: "Somalia" },
      { value: "ZA", label: "South Africa" },
      { value: "GS", label: "South Georgia And The South Sandwich Isl" },
      { value: "LK", label: "Sri Lanka" },
      { value: "SD", label: "Sudan" },
      { value: "SR", label: "Suriname" },
      { value: "SJ", label: "Svalbard And Jan Mayen" },
      { value: "SZ", label: "Swaziland" },
      { value: "CH", label: "Switzerland" },
      { value: "SY", label: "Syrian Arab Republic" },
      { value: "TW", label: "Taiwan, Province Of China" },
      { value: "TJ", label: "Tajikistan" },
      { value: "TZ", label: "Tanzania, United Republic Of" },
      { value: "TH", label: "Thailand" },
      { value: "TG", label: "Togo" },
      { value: "TK", label: "Tokelau" },
      { value: "TO", label: "Tonga" },
      { value: "TT", label: "Trinidad And Tobago" },
      { value: "TN", label: "Tunisia" },
      { value: "TR", label: "Turkey" },
      { value: "TM", label: "Turkmenistan" },
      { value: "TC", label: "Turks And Caicos Islands" },
      { value: "TV", label: "Tuvalu" },
      { value: "UG", label: "Uganda" },
      { value: "UA", label: "Ukraine" },
      { value: "AE", label: "United Arab Emirates" },
      { value: "UM", label: "United States Minor Outlying Islands" },
      { value: "UY", label: "Uruguay" },
      { value: "UZ", label: "Uzbekistan" },
      { value: "VU", label: "Vanuatu" },
      { value: "VE", label: "Venezuela" },
      { value: "VN", label: "Vietnam" },
      { value: "VG", label: "Virgin Islands, British" },
      { value: "VI", label: "Virgin Islands, U.S." },
      { value: "WF", label: "Wallis And Futuna" },
      { value: "EH", label: "Western Sahara" },
      { value: "YE", label: "Yemen" },
      { value: "YU", label: "Yugoslavia" },
      { value: "ZM", label: "Zambia" },
      { value: "ZW", label: "Zimbabwe" },
    ],
  },
};
module.exports.email = {
  fieldType: "email",
  label: "Email address",
  className: "name-field",
  controlId: "email",
  placeholder: "name@example.com",
  options: {
    validationType: "regex",
    regx: /\w+((\.)?(\w)?)?\@{1}\w+\.{1}\w+/
  }
};
module.exports.phone = {
  fieldType: "tel",
  label: "Phone",
  className: "name-field",
  controlId: "phone",
  placeholder: "555-867-5309",
  options: {
    validationType: "regex",
    regx: /\d{3}\-\d{3}\-\d{4}/,
    mask: "ddd-ddd-dddd"
  }
};
module.exports.hsGradYear = {
  fieldType: "number",
  label: "High School Graduation Year",
  className: "name-field",
  controlId: "hsGradYear",
  placeholder: "yyyy",
  options: {
    validationType: "range",
    minVal: 1950,
    maxVal: 2050,
  },
};
module.exports.educationType = {
  // fieldType: "dropdown",
  label: "EducationType",
  className: "select-field",
  controlId: "educationType",
  placeholder: "Select HS Education Type",
  options: {
    type: "list",
    list: [
      { value: "HS Grad", label: "High School Diploma" },
      { value: "GED", label: "GED" },
      { value: "High School", label: "Home School Diploma" },
      { value: "HS Grad", label: "None, I'm in High School" },
      { value: "GED", label: "None, I'm getting my GED" },
      { value: "Home School", label: "None, I'm being Home Schooled" },
      { value: "No Equivalent", label: "None" },
    ],
  },
};
module.exports.hsGrad = {
  fieldType: "checkbox",
  controlId: "hsGrad",
  className: "",
  label: "Do you have a highschool diploma?",
};
module.exports.ged = {
  fieldType: "checkbox",
  controlId: "ged",
  className: "",
  label: "Do you have a GED?",
};
module.exports.militaryStatus = {
  fieldType: "checkbox",
  label: "Are you current or former U.S. Military, Guard, or Reserve?",
  className: "form-label-text form-label",
  controlId: "militaryStatus",
};
module.exports.militaryReleaseDate = {
  fieldType: "text",
  label: "Military Release Date",
  className: "name-field",
  controlId: "militaryReleaseDate",
  placeholder: "mm-yyyy",
  options: {
    validationType: "regex",
    regx: /(0[1-9]|1[0-2])\-(19|20\d{2})/,
    mask: "dd-dddd"
    // minVal: 1950,
    // maxVal: 2050,
  },
};
module.exports.birthday = {
  fieldType: "date",
  label: "Date of Birth",
  className: "dob-field",
  controlId: "birthday",
  maxlength: 8,
};
// module.exports.states = {
//   type: "dropdown",
//   label: "Current or Last High School",
//   className: "name-field",
//   controlId: "states",
//   placeholder: "Select State",
//   options: {
//     type: "list",
//     list: [
//       { value: "AL", label: "Alabama" },
//       { value: "AK", label: "Alaska" },
//       { value: "AS", label: "American Samoa" },
//       { value: "AZ", label: "Arizona" },
//       { value: "AR", label: "Arkansas" },
//       { value: "AA", label: "Armed Forces Americas (Except Canada)" },
//       { value: "AE", label: "Armed Forces Europe, Middle East, Africa" },
//       { value: "AP", label: "Armed Forces Pacific" },
//       { value: "CA", label: "California" },
//       { value: "CO", label: "Colorado" },
//       { value: "CT", label: "Connecticut" },
//       { value: "DE", label: "Delaware" },
//       { value: "DC", label: "District of Columbia" },
//       { value: "FM", label: "Federated States of Micronesia" },
//       { value: "FL", label: "Florida" },
//       { value: "GA", label: "Georgia" },
//       { value: "GU", label: "Guam" },
//       { value: "HI", label: "Hawaii" },
//       { value: "ID", label: "Idaho" },
//       { value: "IL", label: "Illinois" },
//       { value: "IN", label: "Indiana" },
//       { value: "IA", label: "Iowa" },
//       { value: "KS", label: "Kansas" },
//       { value: "KY", label: "Kentucky" },
//       { value: "LA", label: "Louisiana" },
//       { value: "ME", label: "Maine" },
//       { value: "MD", label: "Maryland" },
//       { value: "MA", label: "Massachusetts" },
//       { value: "MI", label: "Michigan" },
//       { value: "MN", label: "Minnesota" },
//       { value: "MS", label: "Mississippi" },
//       { value: "MO", label: "Missouri" },
//       { value: "MT", label: "Montana" },
//       { value: "NE", label: "Nebraska" },
//       { value: "NV", label: "Nevada" },
//       { value: "NH", label: "New Hampshire" },
//       { value: "NJ", label: "New Jersey" },
//       { value: "NM", label: "New Mexico" },
//       { value: "NY", label: "New York" },
//       { value: "NS", label: "No State" },
//       { value: "NC", label: "North Carolina" },
//       { value: "ND", label: "North Dakota" },
//       { value: "MP", label: "Northern Mariana Islands" },
//       { value: "OH", label: "Ohio" },
//       { value: "OK", label: "Oklahoma" },
//       { value: "OR", label: "Oregon" },
//       { value: "PW", label: "Palau" },
//       { value: "PA", label: "Pennsylvania" },
//       { value: "PR", label: "Puerto Rico" },
//       { value: "RI", label: "Rhode Island" },
//       { value: "SC", label: "South Carolina" },
//       { value: "SD", label: "South Dakota" },
//       { value: "TN", label: "Tennessee" },
//       { value: "TX", label: "Texas" },
//       { value: "VT", label: "Vermont" },
//       { value: "VI", label: "Virgin Islands" },
//       { value: "VA", label: "Virginia" },
//       { value: "WA", label: "Washington" },
//       { value: "WV", label: "West Virginia" },
//       { value: "WI", label: "Wisconsin" },
//       { value: "WY", label: "Wyoming" }
//     ],
//   },
// };
