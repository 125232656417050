import React from "react";
// import Row from "react-bootstrap/Row";
import "./intro.scss";

const Intro = (prop) => {
  return (
    <div className="intro-section px-4 mx-0">
      <p className="intro-text">
        {prop.name || ""},
        <br />
        <br />
        {prop.copy}
      </p>
    </div>
  );
};

export default Intro;
