import React from "react";
//import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "./reportHero.scss";

const ReportHero = (prop) => {
  return (
    <div className="report-hero pad-gutter">
      <Container>
        <div className="row title">
          <p className="headline text">Custom Jobs Report:</p>
          <p className="subtext text">{prop.copy ? prop.copy : ""}</p>
        </div>
      </Container>
    </div>
  );
};

export default ReportHero;
