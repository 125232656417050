import React from "react";

import "./postForm.scss";
//import Button from 'react-bootstrap/Button';
import { Container, Col } from "react-bootstrap";


const PostForm = (props) => {
  // state = {
  //   info: [],
  // };

  // refreshPage() {
  //   window.location.reload(false);
  // }
  const backToForm = () => {
    window.scrollTo(0, 0);
  };
  //render() {
  return (
    <Container className="post px-lg-0">
      <div>
        <Col lg={12} className="">
          {props.noni === undefined && (
            <div>
              <h3>What to expect...</h3>
              <br></br>

              <p>
                Your Custom Jobs Report will arrive within 30 minutes. A UTI
                admissions representative will be in touch to answer your
                questions and show how you can get trained in less than a year.
                <sup>1</sup>
              </p>
            </div>
          )}
          <br></br>
          <p className="bold-text">Your report will include info on:</p>
          <br></br>
          <ul className="mx-5">
            <li>
              Jobs in demand right now in your state and across the country
              <sup>2</sup>{" "}
            </li>
            <li>
              The demand for skilled technicians in your field of interest
            </li>
            <li>Average earnings for trained professionals</li>
            <li>Expert advice from some of UTI's 220,000+ graduates</li>
            <li>
              Industry insights from some of the nation's leading employers and
              why they hire UTI grads<sup>2</sup>
            </li>
          </ul>
          <br></br>
          <p className="bold-text">
            Thousands of students across the nation have taken the first step,
            and now you can too!
          </p>
        </Col>
      </div>
      {props.submitted === undefined && (
        <div>
          <Col
            className="b2f-btn d-xl-none d-lg-none d-xl-block"
            md={{ span: 4, offset: 4 }}
          >
            <a href="#" onClick={backToForm}>
              Back to Form
            </a>
          </Col>
        </div>
      )}
    </Container>
  );
  //}
};

export default PostForm;
