import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Form from "react-bootstrap/Form";
import { FormContext } from "../../context/formContext";

function MapOptions(settings) {
  // console.log('MapOptions()');
  // console.log(settings);
  let mappedItems = [];

  if (settings.options["type"] === "range") {
    for (let i = settings.options["minVal"]; i <= settings.options["maxVal"]; i++) {
      mappedItems.push(
        <option key={`option-${settings.label}-${i}`} value={i}>
          {i}
        </option>
      );
    }
  } else if (settings.options["type"] === "list") {
    for (let i = 0; i < settings.options.list.length; i++) {
      mappedItems.push(
        <option
          key={`option-${settings.options.list[i].value}-${settings.options.list[i].value}`}
          value={settings.options.list[i].value}
        >
          {settings.options.list[i].label}
        </option>
      );
    }
  }
  return mappedItems;
}

const DropDown = forwardRef((props, ref) => {
  // console.log('DropDown()');
  const [touched, setTouched] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const payload = useContext(FormContext);

  //console.log(`props.defaultValue: ${props.defaultValue}`);
  //let { placeholder, label, className, controlId } = props.settings;
  let errorClass = "";
  let options = MapOptions(props.settings);

  useEffect(() => {
    //console.log("() for dropDown...");
    //console.log(props.settings.controlId);
    setErrorStatus(false);
    if (touched) {
      // console.log(`dropdown list ${props.settings.controlId} touched, setting error status...`);
      // console.log(props.settings.controlId);
      // console.log(payload[props.settings.controlId]);
      setErrorStatus(payload[props.settings.controlId] === "");
      //console.log(`errorStatus: ${errorStatus}`);
    }
  }, [props]);

  errorClass = errorStatus ? "invalid" : "";

  const handleOnChange = (e, controlId) => {
    //console.log("dropdown on change...");
    payload.updateState(controlId, e.target.value);
    setTouched(true);
    if (e.target.value !== "") {
      setErrorStatus(false);
      document
        .getElementById(`select-${controlId}`)
        .classList.remove("invalid");
    } else {
      setErrorStatus(true);
    }
  };

  const handleOnBlur = (e, controlId) => {
    setTouched(true);
    let val =
      e === null
        ? document.getElementById(`select-${controlId}`).value
        : e.target.value;
    payload.updateState(controlId, val);
    if (val != "") {
      setErrorStatus(false);
      document
        .getElementById(`select-${controlId}`)
        .classList.remove("invalid");
    } else {
      setErrorStatus(true);
    }
    return;
  };

  useImperativeHandle(ref, () => ({ handleOnBlur }));

  return (
    <Form.Group controlId={`form.${props.settings.controlId}`} className={props.cols}>
      <Form.Label className="form-label-text">{props.settings.label}</Form.Label>
      <Form.Select
        className={`${props.settings.className} ${errorClass}`}
        defaultValue={payload[props.settings.controlId]}
        id={`select-${props.settings.controlId}`}
        onChange={(e) => {
          handleOnChange(e, props.settings.controlId);
        }}
        onBlur={(e) => {
          handleOnBlur(e, props.settings.controlId);
        }}
      >
        <option placeholder={`${props.settings.placeholder}`} value="">
          {props.settings.placeholder}
        </option>
        {options}
      </Form.Select>
      {errorStatus && (
        <p className="error-text">Please enter {props.settings.label}</p>
      )}
    </Form.Group>
  );
});

export default DropDown;
