import React, { Component, useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "./userform.scss";
import { FormContext } from "../../context/formContext";

//class UserForm extends Component {
const AOISelect = (props) => {
  const [aoiSelectState, setAOISelectState] = useState({ proceed: true });
  const formContext = useContext(FormContext);

  // var state = {
  //   proceed: true,
  // };

  const enable = () => {
    setAOISelectState({ proceed: false });
  };

  //render() {

  let AOI = props.AOIS.map((item, i) => {
    return (
      <option
        key={`option-${i}`}
        value={item.AreaOfInterestCode}
        className="select-text"
      >
        {item.AreaOfInterestName}
      </option>
    );
  });

  return (
    <div>
      <Form className="form-container">
        <p className="prompt-text">What program are you interested in?</p>
        <Form.Select
          className="drop-down select-field"
          id="select-aoi"
          onChange={(e) => {
            formContext.updateState("AOI", e.target.value);
            //this.context.initPayload();
            enable();
          }}
        >
          <option className="select-text">Select a Program</option>
          {AOI}
        </Form.Select>
      </Form>
      <div
        className={"cta-btn " + (aoiSelectState.proceed ? "disabled" : "")}
        onClick={() => {
          if (!aoiSelectState.proceed) {
            props.progressStep();
          }
        }}
        disabled={aoiSelectState.proceed}
      >
        <p className="btn-text">Next Step &gt; </p>
      </div>
    </div>
  );
  //}
};

export default AOISelect;
