module.exports.AOI = [
  {
    AreaOfInterestCode: "AU",
    AreaOfInterestName: "Automotive",
    school: "Universal Technical Institute",
    campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
    value: "AU",
    label: "Automotive"
  },
  {
    AreaOfInterestCode: "CNC",
    AreaOfInterestName: "CNC Machining",
    school: "Universal Technical Institute",
    campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
    value: "CNC",
    label: "CNC Machining"
  },
  {
    AreaOfInterestCode: "CR",
    AreaOfInterestName: "Collision Repair",
    school: "Universal Technical Institute",
    campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
    value: "CR",
    label: "Collision Repair"
  },
  {
    AreaOfInterestCode: "DI",
    AreaOfInterestName: "Diesel",
    school: "Universal Technical Institute",
    campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
    value: "DI",
    label: "Diesel"
  },

  {
    AreaOfInterestCode: "WELD",
    AreaOfInterestName: "Welding",
    school: "Universal Technical Institute",
    campus_id: "B385D329-26E1-E311-B3A5-0017A477003A",
    value: "WELD",
    label: "Welding" 
  },
];

// {
//     "AreaOfInterestCode": "MA",
//     "AreaOfInterestName": "Marine",
//     "school": "MMI - Marine Mechanic School"
// },
// {
//     "AreaOfInterestCode": "MO",
//     "AreaOfInterestName": "Motorcycle",
//     "school": "MMI - Motorcycle Mechanic School"
// },
// {
//     "AreaOfInterestCode": "NASCAR",
//     "AreaOfInterestName": "NASCAR",
//     "school": "Universal Technical Institute",
//     "campus_id": "B385D329-26E1-E311-B3A5-0017A477003A"
// },
