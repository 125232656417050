import React, { Component } from "react";
import Axios from "axios";
import "./report.scss";
import Footer from "../../major/Footer/Footer";
import SubFooter from "../../major/SubFooter/SubFooter";
import Container from "react-bootstrap/Container";
import Intro from "../../minor/reportParts/Intro/intro";
import UtiDisclaimer from "../../minor/reportParts/UtiDisclaimer/utiDisclaimer";
import CncNatDisclaimer from "../../minor/reportParts/cncNatDisclaimer/cncNatDisclaimer";
import CncNatJobsDisclaimer from "../../minor/reportParts/cncNatJobsDisclaimer/cncNatJobsDisclaimer";
import JobsDisclaimer from "../../minor/reportParts/JobsDisclaimer/jobsDisclaimer";
import AoiInfoBox from "../../minor/reportParts/AoiInfoBox/aoiInfoBox";
import Testimonials from "../../minor/reportParts/Testimonials/testimonial";
import TipsTestimonials from "../../minor/reportParts/TipsTestimonials/tipsTestimonial";
import AoiSalaryChart from "../../minor/reportParts/AoiSalaryChart/aoiSalaryChart";
import HighStates from "../../minor/reportParts/HighStates/highStates";
import UTISnapshot from "../../minor/reportParts/UTISnapshot/utiSnapshot";
import HelpfulLinks from "../../minor/reportParts/HelpfulLinks/helpfulLinks";
import AOIOverview from "../../minor/reportParts/Overview/jobOverview";
import TechList from "../../minor/reportParts/TechList/techList";
import ReportOptions from "../../minor/reportParts/ReportOptions/reportOptions";

import ReportHero from "../../minor/reportParts/ReportHero/ReportHero";
import NationalSalary from "../../minor/reportParts/NationalSalary/nationalSalary";
import BehindScenes from "../../minor/reportParts/BehindScenes/behindScenes";
import NationalAoiInfo from "../../minor/reportParts/NationalAoiInfo/nationalAoiInfo";
import AoiDisclaimer from "../../minor/reportParts/AoiDisclaimer/aoiDisclaimer";
import { Col, Row } from "react-bootstrap";

// AOI Templates Import

// const payload = useContext(FormContext);
// let zipcode = payload.zipCode;

class Report extends Component {
  state = {
    copy: "",
    jobsData: "",
    cncStatus: "false",
  };

  componentDidMount() {
    let path = window.location.pathname.split("/")[2];

    let reqInfo =
      process.env.NODE_ENV === "development"
        ? "https://utiedu-apihub-dev.azurewebsites.net/jobs/retrieve"
        : "https://utiedu-apihub-dev.azurewebsites.net/jobs/retrieve";
    Axios.post(reqInfo, {
      testId: path,
    }).then((retData) => {
      const stateLocation = retData.data.userState;

      let CNC = "";

      if (retData.data.displayAoi === "CNC") {
        //console.log("IS CNC");
        if (
          stateLocation === "Alaska" ||
          stateLocation === "Hawaii" ||
          stateLocation === "District of Columbia"
        ) {
          //  console.log("is cnc nat");
          CNC = require("../../data/cncNat.json");
          this.setState({
            cncStatus: "true",
          });
        } else {
          // console.log("not alaska/hi");
          CNC = require("../../data/cnc.json");
          this.setState({
            cncStatus: "false",
          });
        }
      }
      const AUTO = require("../../data/automotive.json");
      const CRRT = require("../../data/collision.json");
      const DIESEL = require("../../data/diesel.json");
      const WELD = require("../../data/welding.json");

      const copyText = (item) =>
        ({
          AUTO,
          CNC,
          CRRT,
          DIESEL,
          WELD,
        }[item]);

      let data = copyText(retData.data.displayAoi);
      // console.log("aoi" + retData.data.displayAoi);

      this.setState({
        copy: data,
        jobsData: retData.data,
      });
    });
  }

  render() {
    return (
    
      <Container fluid>
        <Container>
          <Row>
            <Col>
              <ReportHero copy={this.state.copy.hero} />
              <Intro
                copy={this.state.copy.intro}
                name={this.state.jobsData.userName}
              />
              <UtiDisclaimer
                copy={this.state.copy.intro_disclaimer}
                copyNat={this.state.copy.intro_disclaimer}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />
              <AOIOverview
                copy={this.state.copy.aoi_overview}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />
              {/* <CncNatJobsDisclaimer
                copy={this.state.copy.aoi_disclaimer}
                nat={this.state.cncStatus}
              /> */}
              {/* AOI Overview Disclaimer */}
              <JobsDisclaimer
                copy={this.state.copy.aoi_disclaimer}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />
              <TechList copy={this.state.copy.aoi_types_list} />
              <UtiDisclaimer
                copy={this.state.copy.aoi_types_list_disclaimer}
                copyNat={this.state.copy.aoi_types_list_disclaimer}
                nat={this.state.cncStatus}
              />
              <AoiInfoBox
                copy={this.state.copy.state_data}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
                state={this.state.jobsData.userState}
              />
              <JobsDisclaimer
                copy={this.state.copy.state_data_disclaimer}
                nat={this.state.cncStatus}
              />
              {/* <CncNatJobsDisclaimer
                copy={this.state.copy.aoi_disclaimer}
                nat={this.state.cncStatus}
              /> */}
              <AoiSalaryChart
                copy={this.state.copy.state_salary}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />
              <AoiDisclaimer
                copy={this.state.copy.state_salary_disclaimer_2}
                copyNat={this.state.copy.state_salary_disclaimer_2}
                nat={this.state.cncStatus}
              />
              <Testimonials
                copy={this.state.copy.aoi_tips}
                nat={this.state.cncStatus}
                copyNat={""}
              />
              <UtiDisclaimer
                copy={this.state.copy.aoi_tips_disclaimer}
                copyNat={this.state.copy.aoi_tips_disclaimer}
                nat={this.state.cncStatus}
              />
              <TipsTestimonials
                copy={this.state.copy.aoi_employer}
                nat={this.state.cncStatus}
              />
              <UtiDisclaimer
                copy={this.state.copy.aoi_employer_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.aoi_employer_disclaimer}
              />
              <NationalAoiInfo
                copy={this.state.copy.national_jobs_data}
                data={this.state.jobsData}
              />
              <JobsDisclaimer
                copy={this.state.copy.national_jobs_data_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.national_jobs_data_disclaimer}
              />
              <BehindScenes
                copy={this.state.copy.aoi_program_uti}
                nat={this.state.cncStatus}
              />
              <UtiDisclaimer
                copy={this.state.copy.aoi_program_uti_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.aoi_program_uti_disclaimer}
              />
              <NationalSalary
                copy={this.state.copy.national_salaries}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />
              <AoiDisclaimer
                copy={this.state.copy.national_salary_disclaimer_2}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.national_salary_disclaimer_2}
              />
              <HighStates
                copy={this.state.copy.highest_pay_states}
                data={this.state.jobsData}
                nat={this.state.cncStatus}
              />{" "}
              {/* <UtiDisclaimer
                copy={this.state.copy.aoi_disclaimer}
                nat={this.state.cncStatus}
              /> */}
              <AoiDisclaimer
                copy={this.state.copy.highest_pay_states_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.highest_pay_states_disclaimer}
              />
              <UTISnapshot
                copy={this.state.copy.start_training}
                nat={this.state.cncStatus}
              />
              <AoiDisclaimer
                copy={this.state.copy.start_training_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.start_training_disclaimer}
              />
              <HelpfulLinks />
              {/*<ReportOptions />*/}
              <AoiDisclaimer
                copy={this.state.copy.jobs_disclaimer}
                nat={this.state.cncStatus}
                copyNat={this.state.copy.jobs_disclaimer}
              />
              {/*<AoiDisclaimer copy={this.state.copy.aoi_disclaimer}nat={this.state.cncStatus} />
              <HelpfulLinks />
              <UtiDisclaimer copy={this.state.copy.aoi_disclaimer}nat={this.state.cncStatus} /> */}
              {/* <ReportOptions /> */}
              {/* <Footer /> */}
              <SubFooter />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Report;
