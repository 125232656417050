import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./sass/base/index.scss";
import Landing from "./page/Landing/Landing";
import Report from "./page/Report/Report";
import OutPage from "./page/Out/Out";
import NonInquiryPage from "./page/Noni/Noni";
import TopNav from "./major/TopNav/TopNav";
import reportWebVitals from "./reportWebVitals";
import OldReport from "./page/Report/OldReport";
import "bootstrap/dist/css/bootstrap.min.css";
import FormContextProvider from "./context/formContext";

ReactDOM.render(
  <React.StrictMode>
    
      <Router>
      <FormContextProvider>
        <TopNav />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="noni" element={<NonInquiryPage />} />
          <Route path="report" element={<Report />}>
            <Route path=":reportId" element={<Report />} />
          </Route>
          <Route path="oldreport" element={<OldReport />} />
        </Routes>
        </FormContextProvider>
      </Router>
    
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
