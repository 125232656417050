import React, { Component } from "react";
import { motion } from "framer-motion";

// const svgVariants = {
//   // hidden: { opacity: 0 },
//   // visible: {
//   //   opacity: 1,
//   //   transition: {duration: 1 }
//   // }
// };

// const pathVariants = {
//   hidden: { opacity: 0, pathLength: 0 },
//   visible: {
//     opacity: 1,
//     pathLength: 1,
//     transition: {
//       duration: 2,
//     },
//   },
// };

class TestChart extends Component {
  render() {
    return (
      <div className="bell-curve">
        <img src="/bell-curve.jpg" alt="Bell Curve" />
      </div>
    );
  }
}

export default TestChart;
