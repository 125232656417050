import React, { useState, createContext } from "react";
//import payload from "./payload.json";
import AOIs from "../data/aoi";
import { useSearchParams } from "react-router-dom";
import { PresenceContext } from "framer-motion";

export const FormContext = createContext();

const FormContextProvider = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    AOI: "",
    age: "",
    birthday: "",
    country: "US",
    date: new Date().toLocaleDateString("en-US"),
    EWC_Accept: "",
    hsGrad: "",
    hsGradYear: "",
    //GED: "",
    email: "",
    educationType: "",
    firstName: "",
    inquiryId: initInquiryID(),
    lastName: "",
    militaryStatus: "",
    militaryReleaseDate: "",
    phone: "",
    step: 1,
    zipCode: "",
    state: "",
    reportId: "",
    payload: {
      //***For some reason this is an Array and not an object in production. This is probably due to the backend Leads Collection Store and CRM API's.
      //***I will verify to see if this is needed or it can be changed. If it stays like this we will need to use Filter to change the state in our Mutations.
      AdditionalInfo: [
        //Mutation for AOI is called ADD_AOI. The action for this will come from the Aoi.vue component.
        { Key: "AOI", Value: "" },

        { Key: "AcquisitionPoint", Value: "UTIForm042" },

        //Eventually we will add a form id to additional info. Action and Mutation will be created to submit a Form.ID wihen a form is loaded.

        //variation id will eventually be added this will be equal to the JSON object main key

        //Mutation for AGE is called ADD_AGE. This Value will come from the Age field on the form.
        { Key: "Age", Value: "" }, //$('form').find('select[name=age]').val() },

        //Need campus ID for CRM possibly
        { Key: "CampusOfInterestNameId", Value: "" },

        //campus of interest for GA tracking
        { Key: "CampusOfInterestName", Value: "" },

        { Key: "CollectionDate", Value: "" },

        //***Not sure this is needed as there is no value in the payload will need to verifiy.
        //Not listed HS or military Base from the thank you form.
        { Key: "Comments", Value: "" }, //dataManager.ijmData() },

        //This will equal the intial lead URL example start.uti.edu/snap

        //default this vaule to utm_source else default to refererr. This was requested by Don A it was only relevent to UTI.edu
        {
          Key: "ContentPageForConversion",
          Value: document.referrer.substring(0, 499),
        }, // dataManager.conversionURL() },

        //This value will equal the url where the last lead was submitted. So if the user completes the thank you page. It would be start.uti.edu/thank-you or it would be the intial
        //url start.uti.edu/snap.

        //this could be equal to this.$route to string
        { Key: "Conversionurl", Value: document.location.origin }, // dataManager.conversionURL() },

        //This value will be if the user was on Mobile. We could probably just set the default state to "Mobile/mobile is true" since snap is mobile only.

        //This will be a function for breakpoint for mobile tablet and desktop.

        { Key: "CustomJobsReportURL", Value: "" },

        { Key: "Device", Value: "" }, // dataManager.userAgent().Device },

        //This value will have a mutation called ADD_EWC. This mutation will need to encode the ewc text in the EWC.vue component.
        { Key: "EWC_Language", Value: "" }, //encodeURIComponent($('.ewc').html()) },

        //***This key is probably not need since this is snap only. We could make a mutation for it if facebook ads run through this app also or just have it setup for the
        //facebook specific app.
        //equal utm_adid need to add this to our query string mutation
        { Key: "FacebookUserId", Value: "" }, //dataManager.fbId() },

        //This value will need to be created a mutation for this will be ADD_GCLID. This is used for google analytics.

        ///Ask Eric and David about this. This is the query parameter gclid
        { Key: "GCLID", Value: "" }, //dataManager.gclId() },

        //This is a value that is needed for google analytics. The mutation for this can be called ADD_GACLIENTID.
        { Key: "GoogleAnalyticsClientId", Value: "" }, //dataManager.gaClientID() },

        //This value comes for a datamanger get request using Ipify. This will need a Get request and a mutation. Code for this can be found in datamanger and I also have it below:
        //getIp: function() {
        //         return new Promise(function (resolve, reject) {
        //             if (typeof jQuery !== 'undefined') {
        //                 $(function () {
        //                     try {
        //                         $.getJSON('https://api.ipify.org?format=jsonp&callback=?',
        //                             function (JSON) {
        //                                 resolve(JSON.ip);
        //                             }
        //                         );
        //                     } catch (err) { reject; }
        //                 });
        //             }
        //         });
        //     },
        //Currently not used in CRM but can be added. Due to GA having this.

        {
          Key: "IncomingQS",
          Value: document.location.search.substring(0, 499),
        },

        //inquiryid is the same mutation as CREATE_LEADGUID since it is the same value.
        { Key: "InquiryId", Value: "" },

        { Key: "IpAddress", Value: "" },

        // IsComplete value will only change to True when the user has completed ALL the TY form questions. So TY form submission will change this not intial form submission.the lead will decorate after 15 mins and be submitted to CRM.
        { Key: "IsComplete", Value: false },

        //Mutation for LeadGuid Value is CREATE_LEADGUID
        { Key: "LeadGuid", Value: "" },

        // This value is for marketo only so not sure it will be needed for snapchat.
        ///Added the cookie functions for this in the globalfunctions folder currently named datalayer.js
        { Key: "Marketocookieid", Value: "" }, // dataManager.mktoID() },

        //The mutation for this will be called ADD_OS. This value will be what OS type the user has. Will be helpful to know what they are using.
        //This could be used in the future. Currently GA is handeling this.
        // { Key: "OS", Value: "" }, //dataManager.userAgent().OS.Type + ' ' + dataManager.userAgent().OS.Version },

        //This is for optimizely not needed unless we run it on the project.
        // { Key: "opt_accountid", Value: "" }, //dataManager.optAcctID() },

        //This is for optimizely not needed unless we run it on the project.
        // { Key: "opt_projectid", Value: "" }, //dataManager.optProjID() },

        //This is for optimizely not needed unless we run it on the project.
        // { Key: "opt_expid_varid", Value: "" }, //dataManager.optExpVarIDs() },

        //This value will be equal to the domain the lead came from. Example value is https://start.uti.edu/
        //{ Key: "PreviousPage", Value: document.referrer }, //dataManager.referrer() },

        //The current value of this the same as the Previous Page Key. I beleive this should be equal to the whole URL including query parameters but there is an issue with
        //document.referrer not including the query parameters. I have tested this in the console.
        { Key: "Referrer", Value: document.referrer.substring(0, 499) },

        //This is just equal to the browser user agent may need to call a mutation to set this if the default value does not run right. Tested this in the console
        //in the browser and it gives me a value.
        { Key: "UserAgent", Value: window.navigator.userAgent },

        //*** Just making a note. It is now 4:15AM so I may need to double check the following due to limited sleep. ******

        // This value will be equal the value of the query parameter utm_campaign. If there is no campaign code the default that should be used is CMP-01032-Q0V1H0.
        // I will check with Eric to see if there is a campaign code he would like it to default to since CMP-01032-Q0V1H0 is a direct source and not related to snapchat.
        //12/2/2020 Per Erik default will be CMP-01016-S2L0G5 if there is no campaign code in the url. Just doing this to cover our basis.
        { Key: "utm_campaign", Value: "" }, //dataManager.campaign() },

        //This value will come from the query string of utm_medium
        { Key: "utm_medium", Value: "" }, //dataManager.medium() },

        // Per Erik this should always be hyfn. So we can default it to hyfn without a mutation. hyfn is used for snapchat, Facebook and Instagram.
        { Key: "utm_source", Value: "" }, //dataManager.source() },

        //This value will come from the query string value in the URL utm_content.
        { Key: "utm_content", Value: "" }, //dataManager.content() },

        //This value will come from the query string value in the URL utm_term.
        { Key: "utm_term", Value: "" },

        //This is the vendor for CAPI. This will contain the source or medium through an action to populate this field
        { Key: "uti_SocialMediaVendor", Value: "" },

        //This is for the ID that is pulled from the query string if there is one..Such as the FBCLID or TTCLID for capi. This is based on the social
        //media vendor.
        { Key: "uti_SocialMediaId", Value: "" },

        //This is for if the user OPT's OUT of CAPI the base value will be false.
        { Key: "uti_SocialMediaDataSharingOptout", Value: "false" },
      ],
      Addresses: [
        {
          //This is the default value for AddressType. It does not change.
          AddressType: "L",

          //This is equal to the Country that is selected from the form. Mutation will be ADD_COUNTRY.
          Country: "",

          //This is equal to the zipcode that is entered from the form. Mutation will be ADD_POSTALCODE.
          PostalCode: "",
        },
      ],
      AreasOfInterest: [
        {
          //This Code key value aoi is going to be equal to the form AOI value above in state. This will just need to be added to the Mutation ADD_AOI
          Code: "",
          Description: null,
          Type: "AOI",
          IsPrimary: true,
        },
      ],
      DateOfBirth: "",
      // This is if they are a HS GRAD. Mutation for this will be ADD_EDUCATION
      //*** I will need to verifiy with Mark under what conditions this is acutally set.
      Education: "", //edu,
      EmailAddresses: [
        {
          //This is the value of the emailaddress on the form. Mutation for this will be ADD_EMAILADDRESS
          Address: "",
          Type: "P",
        },
      ],
      //This value will come from the form input First Name. Mutation for this will be ADD_FIRSTNAME
      FirstName: "", //$('form').find('input[name=first_name]').val().trim(),
      //This value is default to 6 if Grad Year is entered. This could be added to the mutation ADD_GRADYEAR
      GradMonth: "", //gradmonth,
      //This will be the value of the Grad Year entered from the form. Mutation will be ADD_GRADYEAR
      GradYear: "", //$('form').find('input[name=grad_date]').val().trim(),
      HighSchoolCity: "",
      HighSchoolID: null,
      HighSchoolState: "",
      //For some reason this is another spot for the AOI value This will be added to the ADD_AOI mutation or we can set it equal to this.state.AOI?
      InterestCode: "", //$('form').attr('data-area-of-interest'),
      IPAddress: "",
      //This value will be true or false depending if they checked the is military checkbox. Mutation will be ADD_ISMILITARY. Set default state to false.
      IsMilitary: false, //$('form').find('input[name=military]').is(':checked'),
      //This value will come from the Last Name field in the form. Mutation will be called ADD_LASTNAME.
      LastName: "", //$('form').find('input[name=last_name]').val().trim(),
      MilitaryState: "",
      MilitaryID: "",
      //This will be the Date entered format is 12/2/2020 if IsMilitary is true. Mutation will be ADD_MILITARYSEPERATIONDATE.
      MilitarySeparation: "", //$('form').find('input[name=release_date]').val().trim(),
      PhoneNumbers: [
        {
          // This date should show the current date with this format 12/2/2020. Mutation ADD_EWCDATE.
          EWCDate: "", //date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear(),

          //This shows a value of "Form" need to look at the jquery value function to find this attribuite or add the attribute to the ewc component. Mutation ADD_EWCDISCLAIMERREFID
          EWCDisclaimerRefId: "Form", //$('.ewc').attr('data-dis-id'),

          //*** These values are set to true if they submit the first lead. Need to figure out the attribute.
          EWCFlag: true, // $('.ewc').attr('data-dis-id') !== undefined ? $('.ewc').attr('data-dis-id').length > 0 ? true : false : false,
          IsPrimary: true,
          OkToText: true,

          //This is the phone number value field from the form. The final input state does not include -'s. So a phone number looks like 6125448520. Mutation would be ADD_PHONENUMBER.
          //Type is also equal to "C".
          Number: "", //$('form').find('input[name=phone_number]').val().trim().replace(/\D/g, ""),
          Type: "C",
        },
      ],
      //This is used for the main PMLP page for reps. This will not be needed for Snapchat or any paid social or search.
      //RepCode: "" //dataManager.repCode()
    },
  });

  // const updateFormFieldState = (field, data) => {
  //   setState((previousState) => {
  //     return { ...previousState, [field]: data };
  //   });
  // };

  const updateState = (field, data) => {
    setState((previousState) => {
      return { ...previousState, [field]: data };
    });
  };

  const initPayload = () => {
    // console.log("initPayload()");
    // setState((previousState) => {
    //   previousState.inquiryId = initInquiryID();
    // });
    initAdditionalInfo();
    initAddressInfo();
    initAreasOfInterest();
    initEmailAddresses();
    initPhoneNumbers();
    setState((previousState) => {
      previousState.payload.Education = "";
      previousState.payload.FirstName = previousState.firstName;
      previousState.payload.LastName = previousState.lastName;
      previousState.payload.GradYear = "";
      previousState.payload.InterestCode = previousState.AOI;
      previousState.payload.IsMilitary = "";
      return previousState;
    });
    initSocialMediaData();
  };

  function initAdditionalInfo() {
    setState((previousState) => {
      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "AOI";
      })[0]["Value"] = previousState.AOI;

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "Age";
      })[0]["Value"] = previousState.age;

      /// Should this date/time be set on submit instead of here?
      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "CollectionDate";
      })[0]["Value"] = new Date(new Date().toString().split("GMT")[0] + " UTC")
        .toISOString()
        .split(".")[0];

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "FacebookUserId";
      })[0]["Value"] =
        searchParams.get("fbclid") === null ? "" : searchParams.get("fbclid");

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "GCLID";
      })[0]["Value"] =
        searchParams.get("gclid") === null ? "" : searchParams.get("gclid");

      /// ga is not defined error
      // if (typeof ga === "function") {
      //   previousState.payload.AdditionalInfo.filter((el) => {
      //     return el["Key"] === "googleAnalyticsClientId";
      //   })[0]["Value"] = ga.getAll()[0].get("clientId");
      // }

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "InquiryId";
      })[0]["Value"] = previousState.inquiryId;

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "LeadGuid";
      })[0]["Value"] = previousState.inquiryId;

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "utm_campaign";
      })[0]["Value"] =
        searchParams.get("utm_campaign") === null
          ? "CMP-01016-S2L0G5"
          : searchParams.get("utm_campaign");

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "utm_medium";
      })[0]["Value"] =
        searchParams.get("utm_medium") === null
          ? "direct"
          : searchParams.get("utm_medium");

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "utm_source";
      })[0]["Value"] =
        searchParams.get("utm_source") === null
          ? document.referrer
          : searchParams.get("utm_source");

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "utm_content";
      })[0]["Value"] =
        searchParams.get("utm_content") === null
          ? ""
          : searchParams.get("utm_content");

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "utm_term";
      })[0]["Value"] =
        searchParams.get("utm_term") === null
          ? ""
          : searchParams.get("utm_term");

      // const campaign = typeof(searchParams.get("utm_campaign")) === Array ? searchParams.get("utm_campaign")[0] === '' ? "CMP-01016-S2L0G5" : searchParams.get("utm_campaign")[0] : searchParams.get("utm_campaign")[0];
      // const medium = typeof(searchParams.get("utm_medium")) === Array ? searchParams.get("utm_medium")[0] === '' ? "direct" : searchParams.get("utm_medium")[0] : searchParams.get("utm_medium")[0];
      // const source = typeof(searchParams.get("utm_source")) === Array ? searchParams.get("utm_source")[0] === '' ? document.referrer : searchParams.get("utm_source")[0] : searchParams.get("utm_source")[0];
      // const content = typeof(searchParams.get("utm_content")) === Array ? searchParams.get("utm_content")[0] === '' ? '' : searchParams.get("utm_content")[0] : searchParams.get("utm_content")[0];
      // const term = typeof(searchParams.get("utm_term")) === Array ? searchParams.get("utm_term")[0] === '' ? '' : searchParams.get("utm_term")[0] : searchParams.get("utm_term")[0];

      return previousState;
    });
  }

  function initAddressInfo() {
    setState((previousState) => {
      previousState.payload.Addresses[0].PostalCode = previousState.zipCode;
      previousState.payload.Addresses[0].Country = previousState.country;
      return previousState;
    });
  }

  function initAreasOfInterest() {
    setState((previousState) => {
      previousState.payload.AreasOfInterest[0].Code = previousState.AOI;
      previousState.payload.AreasOfInterest[0].Description = AOIs.AOI.filter(
        (aoi) => aoi.AreaOfInterestCode === previousState.AOI
      )[0].AreaOfInterestName;
      return previousState;
    });
  }

  function initEmailAddresses() {
    setState((previousState) => {
      previousState.payload.EmailAddresses[0].Address = previousState.email;
      return previousState;
    });
  }

  function initPhoneNumbers() {
    setState((previousState) => {
      previousState.payload.PhoneNumbers[0].EWCDate = previousState.date;
      previousState.payload.PhoneNumbers[0].Number = previousState.phone;
      return previousState;
    });
  }

  function initInquiryID() {
    let s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    let guidid =
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4();

    return guidid;
  }

  function initSocialMediaData() {
    console.log("initSocialMediaData()");
    let socialMediaId = "";
    let socialMediaVendor = "";

    // let medium = state.payload.AdditionalInfo.filter((el) => {
    //   return el["Key"] === "utm_medium";
    // })[0]["Value"];

    let medium = searchParams.get("utm_medium");

    if (medium) {
      
      let source = searchParams.get("utm_source");
      console.log(`source: ${source}`);

      if (/facebook|instagram|fbig/.test(medium)) {
        socialMediaVendor = "Facebook";
        socialMediaId = searchParams.get("fbclid");
      } else if (/snapchat/.test(medium)) {
        socialMediaVendor = "Snapchat";
      } else if (/tiktok/.test(medium)) {
        socialMediaVendor = "TikTok";
        socialMediaId = searchParams.get("ttclid");
      } else if (/sem/.test(medium) && /google|bing/.test(source)) {
        socialMediaVendor = source;
        socialMediaId = searchParams.get("gclid");
      }
    }

    setState((previousState) => {
      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "uti_SocialMediaVendor";
      })[0]["Value"] = socialMediaVendor;
      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "uti_SocialMediaId";
      })[0]["Value"] = socialMediaId;
      return previousState;
    });
  }

  const finalizePayload = async () => {
    // console.log("finalizePayload()");
    setState((previousState) => {
      previousState.payload.FirstName = previousState.firstName;
      previousState.payload.LastName = previousState.lastName;

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "Age";
      })[0]["Value"] = previousState.age;

      previousState.payload.AdditionalInfo.filter((el) => {
        return el["Key"] === "CustomJobsReportURL";
      })[0]["Value"] = previousState.reportId;

      previousState.payload.DateOfBirth = previousState.birthday;
      previousState.payload.PhoneNumbers[0].Number =
        previousState.phone.replace(/\D/g, "");
      previousState.payload.EmailAddresses[0].Address = previousState.email;
      previousState.payload.Addresses[0].PostalCode = previousState.zipCode;
      previousState.payload.Addresses[0].Country = previousState.country;
      previousState.payload.PhoneNumbers[0].EWCFlag = previousState.EWC_Accept;
      previousState.payload.PhoneNumbers[0].OkToText = previousState.EWC_Accept;
      previousState.payload.PhoneNumbers[0].EWCDate = previousState.date;

      if (previousState.step === 3) {
        previousState.payload.AdditionalInfo.filter((el) => {
          return el["Key"] === "IsComplete";
        })[0]["Value"] = true;
      }

      if (previousState.age < 20) {
        previousState.payload.Education = previousState.educationType;
      } else {
        previousState.payload.Education =
          previousState.hsGrad === true ? "HS Grad" : "No Equivalent";
      }
      previousState.payload.GradYear = previousState.hsGradYear;

      if (previousState.hsGradYear !== "") {
        previousState.payload.GradMonth = "6";
      }

      previousState.payload.IsMilitary =
        previousState.militaryStatus === ""
          ? false
          : previousState.militaryStatus;

      previousState.payload.MilitarySeparation =
        previousState.militaryReleaseDate;

      // console.log("done w/ finalizePayload()");
      return previousState;
    });
  };

  return (
    <FormContext.Provider
      value={{
        ...state,
        updateState,
        initPayload,
        finalizePayload,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default FormContextProvider;
