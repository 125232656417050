import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./utiSnapshot.scss";

const UTISnapshot = (prop) => {
  //var hasScholarship = Object.is(prop.copy.amount, "")
  function HasScholarship() {
    //var isEmpty = Object.is(prop.copy['title'], "")
    // console.log(prop.copy ? prop.copy.text : "no")
    if (prop.copy && prop.copy.amount) {
      return (
        <Row>
          <Col md={2}>
            <div className="funky-shape"></div>
          </Col>
          <Col md={10}>
            <h2
              dangerouslySetInnerHTML={{
                __html: prop.copy ? prop.copy.amount : "",
              }}
            ></h2>
            <p className="uti-points-a">
              <strong
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.sub_text : "",
                }}
              ></strong>
            </p>{" "}
            <hr />
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  return (
    <Row className="uti-snapshot mx-0">
      <Col md={12}>
        <h1>{prop.copy ? prop.copy.title : ""}</h1>
      </Col>
      <Col md={6} className="text">
        <p
          dangerouslySetInnerHTML={{ __html: prop.copy ? prop.copy.text : "" }}
        ></p>
      </Col>

      <Col md={6} className="uti-points">
        <HasScholarship />
        <Row>
          <Col md={2}>
            <div className="funky-shape"></div>
          </Col>

          <Col md={10} >
            <p className="uti-points-b">
              <strong
                dangerouslySetInnerHTML={{
                  __html: prop.copy ? prop.copy.sub_text_two : "",
                }}
              ></strong>
            </p>

            <h2
              className="alter-h2"
              dangerouslySetInnerHTML={{
                __html: prop.copy ? prop.copy.weeks : "",
              }}
            ></h2>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UTISnapshot;
