import React, {
  useContext,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Form from "react-bootstrap/Form";
import { isValidPhoneNumber } from "libphonenumber-js";

import { FormContext } from "../../context/formContext";

const Input = forwardRef((props, ref) => {
  const payload = useContext(FormContext);
  let errorClass = "";
  const [inputTouched, setInputTouched] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    //console.log("() for input...");
    setErrorStatus(false);
    if (inputTouched) {
      let newStatus = getErrorStatus(payload[props.settings.controlId]);
      //console.log(`newStatus: ${newStatus}`);
      //setErrorStatus(getErrorStatus(payload[props.settings.controlId]));
      setErrorStatus(newStatus);
      //console.log(`new error status: ${newStatus}`);
      errorClass = errorStatus ? "invalid" : "";
    }
  }, [props]);

  const handleInputChange = (e, controlId) => {
    // console.log(`handleInputChange for ${controlId}`);
    setInputTouched(true);
    payload.updateState(controlId, e.target.value.trim());
    //console.log(`input value: ${e.target.value}`);
    if (e.target.value != "") {
      setErrorStatus(getErrorStatus(e.target.value.trim()));
    } else {
      setErrorStatus(true);
    }
    //if(errorStatus === false) {document.getElementById(`form.${props.settings.controlId}`).classList.remove('invalid');}
  };

  const handleInputBlur = (e, controlId) => {
    //console.log(`handleInputBlur for ${controlId}`);
    setInputTouched(true);
    let val =
      e === null
        ? document.getElementById(`form.${controlId}`).value
        : e.target.value;
    //console.log(`val: ${val}`);
    if (val != "") {
      setErrorStatus(getErrorStatus(val));
    } else {
      setErrorStatus(true);
    }
    payload.updateState(controlId, val);
  };

  const getErrorStatus = (value) => {
    // console.log(`getErrorStatus(${value})`);
    if (value === "") {
      //console.log('no value');
      return true;
    } else if (props.settings.fieldType === "tel") {
      //console.log('checking tel');
      return /555-?555-?5555/.test(value)
        ? false
        : isValidPhoneNumber(value, "US")
        ? false
        : true;
    } else if (
      props.settings.options != undefined &&
      props.settings.options.validationType !== undefined &&
      props.settings.options.validationType === "regex"
    ) {
      // console.log(`testing ${value} with regex ${props.settings.options.regx}`);
      return props.settings.options.regx.test(value) ? false : true;
    } else if (
      props.settings.options !== undefined &&
      props.settings.options.validationType === "range"
    ) {
      //console.log("checking range");
      return !(
        value > props.settings.options.minVal &&
        value < props.settings.options.maxVal
      );
    } else {
      //console.log('no error...');
      return false;
    }
  };

  const applyMask = (e, settings) => {
    let mask = "";
    if (settings.options !== undefined && settings.options.mask !== undefined) {
      mask = settings.options.mask;
    }

    if (mask !== "") {
      if (mask === "dd-dddd") {
        var lastChar = e.target.value.substring(e.target.value.length - 1);
        if (lastChar != " " && lastChar != "-") {
          lastChar = "";
        }

        var number = e.target.value.replace(/\D/g, "");
        if (number.length === 0) {
          e.taget.value = "";
        }

        if (number.length >= 3) {
          e.target.value = (
            number.substring(0, 2) +
            "-" +
            number.substring(2)
          ).substring(0, 7);
        } else {
          e.target.value = number + lastChar;
        }
      } else if (mask == "ddd-ddd-dddd") {
        //if ($('select[name="country"]').val() == "US") {
          // 1. Prevent the function from running when the user makes a selection within the input
          var selection = window.getSelection().toString();
          if (selection !== "") {
            return;
          }

          // 2. Prevent the function from running when the user presses the arrow keys on the keyboard
          if ([38, 40, 37, 39].includes(e.keyCode)) {
            return;
          }

          // 3. Ignorce spaces
          if (e.keyCode === 32) {
            return;
          }

          var input = e.target.value;
          // 3. Clear out leading 0 or 1
          input = input.replace(/^[0|1]/, "");

          // 4. Clear value of unnecessary characters such as spaces, underscores, dashes, and letters.
          input = input.replace(/[\D\._\-\(\)]+/g, "");
          var size = input.toString().length;
          if (size === 0) {
            input = input;
          } else if (size < 4) {
            //input = "(" + input;
          } else if (size >= 4 && size <= 6) {
            input = input.substring(0, 3) + "-" + input.substring(3, 6);
          } else if (size >= 6) {
            input =
              //"(" +
              input.substring(0, 3) +
              "-" +
              input.substring(3, 6) +
              "-" +
              input.substring(6, 10);
          }

          // 5. return the phone number
          e.target.value = input === 0 ? "" : input.toLocaleString("en-US");
        //}
      }
    }
  };

  // console.log(`${props.settings.controlId}`);
  // console.log(`errorClass = ${errorClass}`);
  // console.log(`errorStatus = ${errorStatus}`);
  errorClass = errorStatus ? "invalid" : "";
  //console.log(`errorClass = ${errorClass}`);

  useImperativeHandle(ref, () => ({ handleInputBlur }));

  return (
    <Form.Group
      controlId={`form.${props.settings.controlId}`}
      className={props.cols}
    >
      <Form.Label className="form-label-text">
        {props.settings.label}
      </Form.Label>
      <Form.Control
        defaultValue={props.defaultValue}
        //onKeyUp={props.isChanged}
        //className={props.settings.className + " " + props.isError}
        className={
          props.settings.className + " " + errorClass + " " + props.isError
        }
        type={props.settings.fieldType}
        placeholder={props.settings.placeholder}
        onChange={(e) => {
          handleInputChange(e, props.settings.controlId);
        }}
        onBlur={(e) => {
          handleInputBlur(e, props.settings.controlId);
        }}
        onKeyUp={(e) => {
          applyMask(e, props.settings);
        }}
        //onBlur={props.onBlur}
        //onKeyPress={props.isChanged}
        maxLength={props.max}
        // isInvalid={isError(props.settings.controlId)}
      />
      {errorStatus && (
        <p className="error-text">
          Please enter a valid {props.settings.label}
        </p>
      )}
    </Form.Group>
  );
});
export default Input;
