import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./behindScenes.scss";

const BehindScenes = (prop) => {
  return (
    <Row className="behind-scenes tech-industry mx-0">
      <Col md={12}>
        <h1>{prop.copy ? prop.copy.title : ""}</h1>
      </Col>

      <Col sm={6} md={6}>
        {" "}
        <p className="pb-0">{prop.copy ? prop.copy.text : ""}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: prop.copy ? prop.copy.testimonial : "",
          }}
        ></p>
      </Col>
      {prop.copy && prop.copy.videoLink ? (
        <Col sm={6} md={6} className="pt-3">
          <div className="video-wrapper">
            <div className="wistia_responsive_padding wistia1">
              <div className="wistia_responsive_wrapper wistia2">
                <iframe
                  src={`https://fast.wistia.net/embed/iframe/${
                    prop.copy
                      ? prop.copy.videoLink + "?seo=false&videoFoam=true"
                      : ""
                  }`}
                  title="Custom Job Report"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  scrolling="no"
                  className="wistia_embed"
                  name="wistia_embed"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
          </div>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default BehindScenes;
