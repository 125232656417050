import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./highStates.scss";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const HighStates = (prop) => {
  const mappedStates = [];
  const mappedStates2 = [];

  if (prop.data.highestPayingStates) {
    prop.data.highestPayingStates.forEach((item, i) => {
      i < 6
        ? mappedStates.push(
            item.state === prop.data.userState ? (
              <li key={i}>
                <strong>
                  {item.rank + "."} {item.state} $
                  {numberWithCommas(parseInt(item.salary))}
                </strong>
              </li>
            ) : (
              <li key={i}>
                {item.rank + "."} {item.state} $
                {numberWithCommas(parseInt(item.salary))}
              </li>
            )
          )
        : mappedStates2.push(
            item.state === prop.data.userState ? (
              <li key={i}>
                <strong>
                  {item.rank + "."} {item.state} $
                  {numberWithCommas(parseInt(item.salary))}
                </strong>
              </li>
            ) : (
              <li key={i}>
                {item.rank + "."} {item.state} $
                {numberWithCommas(parseInt(item.salary))}
              </li>
            )
          );
    });
  }
  if (prop.nat == "false") {
    return (
      <Row className="highest-states mx-0">
        <h1>{prop.copy ? prop.copy.title : ""}</h1>
        <p className="mb-5">
          {prop.copy ? prop.copy.text : ""}{" "}
          {prop.data ? prop.data.userState : ""}{" "}
          {prop.copy ? prop.copy.text_two : ""}
        </p>
        <Col
          xxl={{ span: 4, offset: 3 }}
          lg={{ span: 4, offset: 2 }}
          md={6}
          sm={6}
        >
          <ol>{mappedStates}</ol>
        </Col>
        <Col
          xxl={{ span: 5, offset: 0 }}
          lg={{ span: 4, offset: 0 }}
          md={6}
          sm={6}
        >
          <ol start="7">{mappedStates2}</ol>
        </Col>
      </Row>
    );
  } else {
    return <span></span>;
  }
};

export default HighStates;
