import React, { useState, useContext, useRef, useEffect } from "react";
// import Button from "react-bootstrap/Button";
import { Container, Row, Col, Form } from "react-bootstrap";
import DropDown from "../../minor/formParts/dropDown";
import Input from "../../minor/formParts/input";
import FORM_INPUTS from "../../data/formInputs";
import HELPER_FUNCTIONS from "../../helpers/zipCodeLookup";
import axios from "axios";
import ENDPOINT from "../../data/dev";
import SubmissionPage from "../SubmissionPage/SubmissionPage";
import "./optOutForm.scss";
import { FormContext } from "../../context/formContext";

const OptOutForm = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [aoiTouched, setAOITouched] = useState(false);
  const [fNameTouched, setFnameTouched] = useState(false);
  const [lNameTouched, setLnameTouched] = useState(false);
  const [zipTouched, setZipTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [aoiPresent, setAOIPresent] = useState(false);
  const payload = useContext(FormContext);

  //setAOIPresent(payload.AOI !== "");

  useEffect(() => {
    /// only executes when value of aoiPresent changes
    window.scrollTo(0, 0);
    setAOIPresent(payload.AOI !== "");
  }, [aoiPresent]);

  const aoiComponentRef = useRef();
  const fNameComponentRef = useRef();
  const lNameComponentRef = useRef();
  const zipComponentRef = useRef();
  const emailComponentRef = useRef();

  // const aoiBlurHandler = (event) => {
  //   console.log(`aoiBlurHandler() settting aoiTouched to true`);
  //   setAOITouched(true);
  //   console.log(aoiTouched);
  // };

  // const fNameBlurHandler = (event) => {
  //   console.log('fNameBlurHandler')
  //   setFnameTouched(true);
  // };

  // const lNameBlurHandler = (event) => {
  //   console.log('lNameBlurHandler')
  //   setLnameTouched(true);
  // };

  // const zipBlurHandler = (event) => {
  //   console.log('zipBlurHandler');
  //   setZipTouched(true);
  // };

  // const emailBlurHandler = (event) => {
  //   console.log('emailBlurHandler')
  //   setEmailTouched(true);
  // };

  function submitJobReport() {
    payload["state"] = HELPER_FUNCTIONS.getState(payload.zipCode);

    axios.post(ENDPOINT.endpoint(), payload).then((retData) => {
      const returnHubData = retData.data;
      payload.updateState(
        "reportId",
        "https://cjr.uti.edu/report/" + returnHubData.reportId
      );

      // fetch("/api/sms", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },

      //   body: JSON.stringify({
      //     payload,
      //     returnHubData,
      //   }),
      // })
      //  .then(
      fetch("/api/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload,
          returnHubData,
        }),
      })
        //  )
        .then((res) => res.json())
        .catch((err) => console.log(err));
      // console.log("done w/ submitJobsReport()");
    });
    // console.log("make Submission here");
  }

  const submitBtnHandler = () => {
    //console.log("submitForm()");
    if (payload.AOI === "") {
      if (!aoiTouched) {
        aoiComponentRef.current.handleOnBlur(null, FORM_INPUTS.AOIs.controlId);
      }
    }
    if (!fNameTouched) {
      fNameComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.firstName.controlId
      );
    }
    if (!lNameTouched) {
      lNameComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.lastName.controlId
      );
    }
    if (!zipTouched) {
      zipComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.zipCode.controlId
      );
    }
    if (!emailTouched) {
      emailComponentRef.current.handleInputBlur(
        null,
        FORM_INPUTS.email.controlId
      );
    }

    // setFormIsValid(
    //   payload.firstName !== "" &&
    //     payload.lastName !== "" &&
    //     FORM_INPUTS.email.options.regx.test(payload.email) &&
    //     FORM_INPUTS.zipCode.options.regx.test(payload.zipCode)
    // );

    //if (!formIsValid) {
    if (
      payload.AOI !== "" &&
      payload.firstName !== "" &&
      payload.lastName !== "" &&
      FORM_INPUTS.email.options.regx.test(payload.email) &&
      FORM_INPUTS.zipCode.options.regx.test(payload.zipCode)
    ) {
      //console.log(`form Is Valid: submitting jobs report`);
      submitJobReport();
      setFormSubmitted(true);
    } else {
    }
    //errorClassStuff();
  };

  let formStep = "";
  let headline = "";

  if (!formSubmitted) {
    headline = "Access Your Custom Jobs Report";
    formStep = (
      <Container className="form-container">
        <Form>
          {!aoiPresent && (
            <DropDown
              settings={FORM_INPUTS.AOIs}
              //onBlur={aoiBlurHandler}
              ref={aoiComponentRef}
            />
          )}
          <Input
            settings={FORM_INPUTS.firstName}
            //onBlur={fNameBlurHandler}
            ref={fNameComponentRef}
          />
          <Input
            settings={FORM_INPUTS.lastName}
            //onBlur={lNameBlurHandler}
            ref={lNameComponentRef}
          />
          <Input
            settings={FORM_INPUTS.email}
            //onBlur={emailBlurHandler}
            ref={emailComponentRef}
          />
          <Input
            settings={FORM_INPUTS.zipCode}
            //onBlur={zipBlurHandler}
            ref={zipComponentRef}
          />
        </Form>

        <div className="btn-divv">
          <div>
            <button className="cta-btn" onClick={submitBtnHandler}>
              ACCESS REPORT &gt;
            </button>
          </div>
        </div>
      </Container>
    );
  } else {
    headline = "Check Your Inbox";
    //formStep = <SubmissionPage aoi={selectedAoi} zip={payload.zipCode} />;
    formStep = (
      <div className="post">
        <p class="text-center">Your report has been sent via email.</p>
      </div>
    );
  }
  return (
    <Container className="form-controller">
      <div className="form-wrapper pad-gutter">
        <div>
          <Col className="headline">
            <h2 className="form-text-headline">{headline}</h2>
          </Col>
        </div>
        {formStep}
      </div>
    </Container>
  );
};

export default OptOutForm;
