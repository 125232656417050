import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import Hero from "../../major/Hero/Hero";
// import FormController from "../../major/FormController/FormController";
import OptOutForm from "../../major/OptOutForm/OptOutForm";
import Footer from "../../major/Footer/Footer";
import SubFooter from "../../major/SubFooter/SubFooter";
import "./Out.scss";
//import '../sass/base/main.scss'
import Container from "react-bootstrap/Container";
import FormContextProvidor from "../../context/formContext";
//import Wrapper from "../../major/Wrapper/Wrapper";

class Out extends Component {
  render() {
    return (
      <Container fluid className="landing px-0">
        {/* <Row>
          <h1 className="form-text-headline">{headLine}</h1>
        </Row> */}
        <Hero />
        <div className="form pad-gutter Form-Controller">
          {/* <Container fluid className="landing px-0"> */}
            <FormContextProvidor>
              <OptOutForm />
            </FormContextProvidor>
          {/* </Container> */}
        </div>
        <Footer />
        <SubFooter />
      </Container>
    );
  }
}

export default Out;
