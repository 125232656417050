import React from "react";
//import Button from "react-bootstrap/Button";
import "./submissionPage.scss";
import Container from "react-bootstrap/Container";

const SubmissionPage = (prop) => {
  //console.log("hit submission page");
  let zipcode = prop.zip;
  let aoi = prop.aoi;
  let message = "";

  if (aoi === "CNC") {
    if (
      (zipcode >= 99500 && zipcode <= 99999) ||
      (zipcode >= 20000 && zipcode <= 20099) ||
      (zipcode >= 20200 && zipcode <= 20599) ||
      (zipcode >= 56900 && zipcode <= 56999) ||
      (zipcode >= 96700 && zipcode <= 96999)
    ) {
      message =
        "Alaska, Hawaii and District of Columbia don't have state-level data available for CNC machining. We'll send a report with national data. ";
    } else {
      message = "Your report has been sent via email and text message.";
    }
  } else {
    message = "Your report has been sent via email and text message.";
  }
  return (
    <Container className="inputContainer">
      <p className="report-message">{message}</p>
    </Container>
  );
};

export default SubmissionPage;
