import React from "react";
import TestSVG from "../../SVG/testSVG";
import "./aoiInfoBox.scss";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const aoiInfoBox = (prop) => {
  if (prop.nat === "false") {
    return (
      <div className="aoi-info mx-0">
        <h1 className="text-center">
          {prop.copy ? prop.copy.title : ""}
          {prop.data.userState}
        </h1>
        <p>
          {prop.copy ? prop.copy.text : ""}{" "}
          {`${prop.data.userState} was ${numberWithCommas(
            parseInt(prop.data.stateEmployment)
          )}`}{" "}
          {prop.copy ? prop.copy.text_two : ""}
        </p>
        <div className="icon">
          {/* <TestSVG /> */}

          {prop.copy && prop.state ? (
            <img
              src={`https://cdn.uti.edu/custom-jobs-report/states/${prop.state}.svg`}
              alt={prop.copy ? prop.copy.title : ""}
              height="200"
            />
          ) : (
            ""
          )}
        </div>
        <h1 className="aoi-h1">
          {numberWithCommas(parseInt(prop.data.stateEmployment))}
        </h1>
        <p className="sub-text-ceneter">
          {prop.copy ? prop.copy.emp_stats + " " + prop.data.userState : ""}*
        </p>
      </div>
    );
  } else {
    return <span></span>;
  }
};

export default aoiInfoBox;
